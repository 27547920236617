/* eslint-disable indent */
import {computed, observable, runInAction, action} from 'mobx';
import clone from 'lodash/clone';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import {ICountryInfo} from '@techsee/techsee-ui-common/lib/_contracts/CountryInfo';
import {
    InviteMethodSettings,
    VisualJourneyItem,
    ClientLanguageItem,
    ReferralRegionItem,
    VirtualBackgroundItem
} from '../../../../../models/AccountSettings';
import {InviteMethodForm} from './form-model';
import {ISmsService, ValidationResultCodes} from '../../../../../services/SmsService';
import {IFieldFactory} from '../../../../../app.contracts';
import {OperationResult} from '../../../../../models/OperationResult';
import {IInviteFlowManager} from '../../../_contracts/InviteContracts';
import {InviteMethodInfo} from '../../../../../models/LiveSessionState';
import {MeetingMode, AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {
    emailSendBy,
    MediaTypes,
    ReferralConfigurations,
    VideoFilterType
} from '@techsee/techsee-common/lib/constants/account.constants';
import {getRootStore} from '../../../../../app.bootstrap';
import {calculatePhoneNumberValue} from '@techsee/techsee-ui-common/lib/forms/phone-number-field';
import {ISelectVideoPublisherController} from '../../../../../components/select-video-publisher/controller';
import {Nullable} from '@techsee/techsee-common';
import {DeviceCategory} from '@techsee/techsee-common/lib/constants/utils.constant';
import {countryCodes} from '@techsee/techsee-country-codes/lib/index';

// @ts-ignore
import {sanitizeCountryCode} from '@techsee/techsee-common/lib/utils';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

export interface IInviteMethodController {
    readonly inviteForm: InviteMethodForm;

    readonly isVideoApplicationEnabled: boolean;
    readonly validateNumberInProgress: boolean;
    readonly creatingSessionInProgress: boolean;
    readonly isCustomerRefEnabled: boolean;
    readonly isCoBrowsingEnabled: boolean;
    readonly isPhoneNumberEnabled: boolean;
    readonly isStartWithModesEnabled: boolean;
    readonly isCountryCodeEnabled: boolean;
    readonly isLanguageSelectionEnabled: boolean;
    readonly showSelectVideoPublisher: boolean;
    readonly phoneCountryCodes: string[];
    readonly defaultCountryCode: string | number;
    _selectVideoPublisherController: Nullable<ISelectVideoPublisherController>;

    readonly countriesInfo: ICountryInfo[];
    readonly countryList: ICountryInfo[];
    readonly clientLanguages: ClientLanguageItem[];
    readonly visualJourniesList: VisualJourneyItem[];
    readonly referralRegionList: ReferralRegionItem[];
    readonly virtualBackgroundList: VirtualBackgroundItem[];
    readonly isVirtualBackgroundEnabled: boolean;
    readonly isVisualJourneySelected: boolean;
    readonly isVideoApplicationSelected: boolean;
    readonly isFaceMeetSelected: boolean;
    readonly visualJourneyName: string;
    readonly isInviteByEmail: boolean;
    enableAudio: boolean;
    enableVideo?: boolean;
    displayStartWithAudio: boolean;
    displayStartWithVideo: boolean;
    isAudioSupportedMode: boolean;
    readonly isCobrowsingSelectedMode: boolean;
    isVideoSupportedMode: boolean;
    addressType: AddressTypesEnum;
    deviceCategory: DeviceCategory | null;
    readonly measurementAccountSettings: {
        enabled: boolean;
        enableMarkPoints: boolean;
        unit: string;
    };
    enableMeasurement: boolean;
    isMeasureSupportedMode: boolean;
    allowSendByWhatsApp: boolean;

    audioForRoomToggle(): void;

    videoForRoomToggle(): void;

    measureForRoomToggle(): void;

    validateInviteForm(): PromiseLike<OperationResult>;

    resetInviteForm(): void;

    rebuildForm(addressType: AddressTypesEnum): void;

    updateInviteState(): Promise<void>;

    resetInviteState(): void;

    setCreatingSessionInProgress(inProgress: boolean): void;

    setPhoneNumber(value: string): void;

    setCountryCode(value: string): void;

    setCustomerRef(value: string): void;
}

export class InviteMethodController implements IInviteMethodController {
    private _smsService: ISmsService;

    private _flowManager: IInviteFlowManager;

    private _accountSettings: InviteMethodSettings;

    private _environmentDetect: ITsEnvironmentDetect;

    private _audioConfig: any;

    private _deviceCategory: Nullable<DeviceCategory>;

    private _browserUtilsService: IBrowserUtilsService;

    readonly inviteForm: InviteMethodForm;

    readonly measurementAccountSettings: any;

    allowSendByWhatsApp: boolean;

    _selectVideoPublisherController: Nullable<ISelectVideoPublisherController>;

    @observable private _validateNumberInProgress: boolean = false;

    @observable private _creatingSessionInProgress: boolean = false;

    @observable
    private _countriesInfoList: ICountryInfo[] = [];

    @observable countryList: ICountryInfo[] = [];

    @observable _enableAudio: boolean;

    @observable _enableVideo?: boolean;

    private _displayStartWithAudio: boolean;

    private _displayStartWithVideo: boolean;

    @observable _enableMeasurement: boolean;

    constructor(
        flowManager: IInviteFlowManager,
        accSettings: InviteMethodSettings,
        smsService: ISmsService,
        fieldFactory: IFieldFactory,
        browserUtilsService: IBrowserUtilsService
    ) {
        this._smsService = smsService;
        this._flowManager = flowManager;
        this._accountSettings = accSettings;
        this._environmentDetect = getRootStore().environmentService;
        this._browserUtilsService = browserUtilsService;
        this.countryList = countryCodes;
        this._deviceCategory = this._environmentDetect.deviceCategory(getRootStore().displayTabletAsDesktop);
        this._audioConfig = this.getAudioConfig();
        this._displayStartWithAudio = this.shouldAllowAudio() && this._audioConfig && this._audioConfig.enableAudio;
        this._displayStartWithVideo = this.shouldAllowVideo();
        this._enableAudio = this._displayStartWithAudio && this._audioConfig.startSessionWithAudio;
        this._enableVideo = get(this._accountSettings, 'v2_1.enabled')
            ? this._accountSettings.agentStartSessionWithVideo && this._displayStartWithVideo
            : undefined;
        this.inviteForm = new InviteMethodForm(accSettings, this._flowManager.translate, fieldFactory);
        this.allowSendByWhatsApp = accSettings.allowSendByWhatsApp;
        this._selectVideoPublisherController = this._flowManager._selectVideoPublisherController;
        this.createFormFields(this.inviteForm);
        this.audioForRoomToggle = this.audioForRoomToggle.bind(this);
        this.videoForRoomToggle = this.videoForRoomToggle.bind(this);
        this.measureForRoomToggle = this.measureForRoomToggle.bind(this);

        if (this.isCountryCodeEnabled && this.isPhoneNumberEnabled) {
            Promise.resolve(this.countryList).then((countryList) => {
                runInAction(() => {
                    this._countriesInfoList = countryList.filter(
                        (country) =>
                            accSettings.phoneCountryCodes.indexOf(country.code) > -1 ||
                            `+${accSettings.defaultCountryCode}` === country.dial
                    );

                    const countryCodeValueFromParams = this._browserUtilsService.getParamValue('c');

                    this.initializeAccountCountryCode(countryCodeValueFromParams);
                });
            });
        }

        this.measurementAccountSettings = get(this._accountSettings, 'measurement');
        this._enableMeasurement = this.measurementAccountSettings.enabled;
    }

    get isPhoneNumberEnabled(): boolean {
        return (
            this.isInviteBySMS ||
            this.isInviteByWhatsApp ||
            (this.isInviteByCode && this._accountSettings.disablePhoneNumberForInviteByCode)
        );
    }

    get showSelectVideoPublisher(): boolean {
        const env = this.deviceCategory!;
        const currentMode = get(this._flowManager, 'startWithModesController.currentMode.mode');
        const isCurrentModeSupported = includes(
            [MeetingMode.video, MeetingMode.images, MeetingMode.oneClick],
            currentMode
        );

        return (this._accountSettings.selectVideoPublisher as any)[env] && isCurrentModeSupported;
    }

    get isVideoApplicationEnabled(): boolean {
        return !!find(this._accountSettings.startWithModes.modesOptions, {mode: MeetingMode.videoApplication});
    }

    get isVirtualBackgroundEnabled(): boolean {
        return (
            this._accountSettings.virtualBackground.enabledBlurAgent ||
            this._accountSettings.virtualBackground.enabledImageReplacementAgent
        );
    }

    get isCustomerRefEnabled(): boolean {
        return (
            (this.isInviteByCode && this._accountSettings.customerIdValidationRules.displayForInviteByCode) ||
            (this.isInviteBySMS && this._accountSettings.customerIdValidationRules.display) ||
            (this.isInviteByWhatsApp && this._accountSettings.customerIdValidationRules.display) ||
            (this.isInviteByEmail && this._accountSettings.customerIdValidationRules.displayForInviteByEmail)
        );
    }

    get isCoBrowsingEnabled(): boolean {
        return this._accountSettings.coBrowsing.enabled;
    }

    get isInviteByEmailEnabled(): boolean {
        return (
            this._accountSettings.emailSendBy === emailSendBy.tsEmailService &&
            (this._accountSettings.allowSendByEmail || this._accountSettings.disableEmailForInviteByCode)
        );
    }

    get isInviteByEmail(): boolean {
        return this.addressType === AddressTypesEnum.EMAIL && this.isInviteByEmailEnabled;
    }

    get addressType(): AddressTypesEnum {
        return this._flowManager.inviteState.inviteMethodInfo.addressType;
    }

    get isInviteByCode(): boolean {
        return this.addressType === AddressTypesEnum.INVITE_BY_CODE;
    }

    get isInviteBySMS(): boolean {
        return this.addressType === AddressTypesEnum.SMS;
    }

    get isInviteByWhatsApp(): boolean {
        return this.addressType === AddressTypesEnum.WHATSAPP;
    }

    get isStartWithModesEnabled(): boolean {
        return this._accountSettings.startWithModes.enabled;
    }

    get isCountryCodeEnabled(): boolean {
        return this._accountSettings.addCountryCodeToSms;
    }

    get isLanguageSelectionEnabled(): boolean {
        return this._accountSettings.multiLanguage && !isEmpty(this.clientLanguages);
    }

    get phoneCountryCodes(): string[] {
        return this._accountSettings.phoneCountryCodes;
    }

    get defaultCountryCode(): string | number {
        return this._accountSettings.defaultCountryCode;
    }

    @computed
    get countriesInfo(): ICountryInfo[] {
        return this._countriesInfoList;
    }

    @computed
    get validateNumberInProgress(): boolean {
        return this._validateNumberInProgress;
    }

    @computed
    get creatingSessionInProgress(): boolean {
        return this._creatingSessionInProgress;
    }

    @action
    setCreatingSessionInProgress(inProgress: boolean) {
        this._creatingSessionInProgress = inProgress;
    }

    @computed
    get clientLanguages(): ClientLanguageItem[] {
        return orderBy(
            map(this._accountSettings.clientLanguages, (clientLanguage: Record<string, any>) => {
                const item: ClientLanguageItem = {
                    name: clientLanguage.name,
                    value: clientLanguage.value
                };

                return item;
            }),
            ['name'],
            ['asc']
        );
    }

    @computed
    get selectedClientLanguage(): string {
        const languageName = get(this.inviteForm, `fields.${this.inviteForm.fieldNames.selectedLanguage}.value`, '');

        if (!languageName) {
            return '';
        }

        try {
            return this.clientLanguages.find((clientLanguage) => clientLanguage.name === languageName)!.value;
        } catch (error) {
            return '';
        }
    }

    @computed
    get selectedVirtualBackground(): VideoFilterType {
        const virtualBackground = get(
            this.inviteForm,
            `fields.${this.inviteForm.fieldNames.virtualBackground}.value`,
            VideoFilterType.NONE
        );

        if (!virtualBackground) {
            return this._accountSettings.virtualBackground.default;
        }

        return (
            (this.virtualBackgroundList.find((item) => item.name === virtualBackground)?.value as VideoFilterType) ||
            VideoFilterType.NONE
        );
    }

    @computed
    get visualJourniesList(): VisualJourneyItem[] {
        return this._accountSettings.visualJourney.available;
    }

    @computed
    get virtualBackgroundList(): VirtualBackgroundItem[] {
        const {enabledBlurAgent, enabledImageReplacementAgent} = this._accountSettings.virtualBackground;
        const enabledOptions: VirtualBackgroundItem[] = [
            {
                value: VideoFilterType.NONE,
                name: this._flowManager.translate('REACT.INVITE.VIEW.SELECT_OPTION_NONE')
            }
        ];

        if (enabledBlurAgent) {
            enabledOptions.push({
                value: VideoFilterType.BLUR,
                name: this._flowManager.translate('REACT.INVITE.VIEW.SELECT_OPTION_BLUR')
            });
        }
        if (enabledImageReplacementAgent) {
            enabledOptions.push({
                value: VideoFilterType.BACKGROUND,
                name: this._flowManager.translate('REACT.INVITE.VIEW.SELECT_OPTION_VIDEO_BACKGROUND')
            });
        }

        return enabledOptions;
    }

    @computed
    get isVisualJourneySelected(): boolean {
        return get(this._flowManager, 'startWithModesController.currentMode.mode') === MeetingMode.visualJourney;
    }

    @computed
    get isFaceMeetSelected(): boolean {
        return get(this._flowManager, 'startWithModesController.currentMode.mode') === MeetingMode.faceMeet;
    }

    @computed
    get referralRegionList(): ReferralRegionItem[] {
        return map(ReferralConfigurations, (key, value) => ({name: key, value}));
    }

    @computed
    get selectedReferralRegion(): string {
        const referralRegion = get(this.inviteForm, `fields.${this.inviteForm.fieldNames.referralRegion}.value`, '');

        if (!referralRegion) {
            return this._accountSettings.referralConfiguration;
        }

        try {
            return (find(this.referralRegionList, {name: referralRegion}) as ReferralRegionItem).value;
        } catch (error) {
            return '';
        }
    }

    @computed
    get isVideoApplicationSelected(): boolean {
        return get(this._flowManager, 'startWithModesController.currentMode.mode') === MeetingMode.videoApplication;
    }

    private getAudioConfig() {
        const env = this.deviceCategory;

        return env && this._accountSettings.audioStartMode && (this._accountSettings.audioStartMode as any)[env];
    }

    private shouldAllowAudio() {
        return (
            this._accountSettings.newHeaderFooterLeftbar &&
            this._accountSettings.enableSessionToolbar &&
            this._accountSettings.mediaType === MediaTypes.videoAndAudio
        );
    }

    private shouldAllowVideo() {
        return (
            this._accountSettings.newHeaderFooterLeftbar &&
            this._accountSettings.enableSessionToolbar &&
            (this._accountSettings.mediaType === MediaTypes.videoAndAudio ||
                this._accountSettings.mediaType === MediaTypes.video)
        );
    }

    get deviceCategory() {
        return this._deviceCategory;
    }

    @computed
    get isAudioSupportedMode() {
        return (
            this._flowManager.startWithModesController?.currentMode?.mode === MeetingMode.video ||
            this._flowManager.startWithModesController?.currentMode?.mode === MeetingMode.faceMeet ||
            this._flowManager.startWithModesController?.currentMode?.mode === MeetingMode.videoApplication ||
            get(this._accountSettings, 'v3.enabled')
        );
    }

    @computed
    get isCobrowsingSelectedMode() {
        return get(this._flowManager, 'startWithModesController.currentMode.mode') === MeetingMode.coBrowsing;
    }

    @computed
    get isVideoSupportedMode() {
        return (
            this._flowManager.startWithModesController?.currentMode?.mode === MeetingMode.faceMeet &&
            get(this._accountSettings, 'v2_1.enabled')
        );
    }

    @computed
    get shouldStartWithAudio() {
        return this._enableAudio && this.isAudioSupportedMode;
    }

    @computed
    get shouldStartWithMeasure() {
        return this._enableMeasurement && this.isMeasureSupportedMode;
    }

    @computed
    get isVisualJourneyEnabled(): boolean {
        return this._accountSettings.visualJourney.enabled;
    }

    @computed
    get referralRegion(): boolean {
        return get(
            this.inviteForm,
            `fields.${this.inviteForm.fieldNames.referralRegion}.value`,
            ''
        ) as unknown as boolean;
    }

    @computed
    get visualJourneyName(): string {
        return get(this.inviteForm, `fields.${this.inviteForm.fieldNames.visualJourney}.value`, '') as string;
    }

    @computed
    get visualJourneyAlias(): string {
        const vjName = get(this.inviteForm, `fields.${this.inviteForm.fieldNames.visualJourney}.value`);

        try {
            return this.visualJourniesList.find((journey) => journey.name === vjName)!.alias;
        } catch (error) {
            return '';
        }
    }

    @computed
    get enableAudio() {
        return this._enableAudio;
    }

    @computed
    get enableVideo() {
        return this._enableVideo;
    }

    get displayStartWithAudio() {
        return this._displayStartWithAudio;
    }

    get displayStartWithVideo() {
        return this._displayStartWithVideo;
    }

    @computed
    get enableMeasurement() {
        return this._enableMeasurement;
    }

    @computed
    get isMeasureSupportedMode() {
        return (
            !!this._flowManager.startWithModesController.currentMode &&
            this._flowManager.startWithModesController.currentMode.mode === MeetingMode.appSharing
        );
    }

    @action
    audioForRoomToggle() {
        this._enableAudio = !this._enableAudio;
    }

    @action
    videoForRoomToggle() {
        this._enableVideo = !this._enableVideo;
    }

    @action
    measureForRoomToggle() {
        this._enableMeasurement = !this._enableMeasurement;
    }

    validateInviteForm(): PromiseLike<OperationResult> {
        return new Promise<OperationResult>((resolve) => {
            const changePhoneValidationStatus = (isValidating: boolean) => {
                runInAction(() => (this._validateNumberInProgress = isValidating));
            };

            this.inviteForm.validateForm().then((isFormValid) => {
                if (!isFormValid) {
                    this.inviteForm.displayFieldsErrors();
                    resolve(OperationResult.getFailResult());

                    return;
                }

                if (this.isPhoneNumberEnabled) {
                    changePhoneValidationStatus(true);
                    const {address, countryCode, phoneNumber} = this.setAddressDetails();

                    this._smsService
                        .validatePhoneNumber(phoneNumber, countryCode)
                        .then((result: OperationResult<{lookupPhoneNumber?: string}>) => {
                            changePhoneValidationStatus(false);

                            if (!result.isSuccess) {
                                const lookupPhoneNumber = result.data && result.data.lookupPhoneNumber;

                                if (result.message === ValidationResultCodes.INVALIDNUMBER && lookupPhoneNumber) {
                                    const validatedPhone = `+${lookupPhoneNumber}`;

                                    this._flowManager.lookupNumberController
                                        .showLookupModal({
                                            enteredPhone: address,
                                            validatedPhone: validatedPhone,
                                            isFromChangeNumber:
                                                this._flowManager.changeNumberController.isDisplayConfirmationModal,
                                            logData: {
                                                accountId: this._flowManager.appState.accountId,
                                                userId: this._flowManager.appState.appUser!._id,
                                                roomId: this._flowManager.inviteState.sessionInfo.sessionRoomId
                                            }
                                        })
                                        .then(() => {
                                            const codeWithoutPlus = this.inviteForm.fields.countryCode.value
                                                .toString()
                                                .replace('+', '');
                                            const newPhoneNumber = lookupPhoneNumber.replace(codeWithoutPlus, '');

                                            this.inviteForm.fields.phoneNumber.setValue(newPhoneNumber);

                                            resolve(OperationResult.getSuccessResult());
                                        })
                                        .catch(() => {
                                            resolve(OperationResult.getSuccessResult());
                                        });
                                } else {
                                    //TODO: Check where I take carrier name from??
                                    const errMsg = this._flowManager.translate(
                                        'REACT.INVITE.VIEW.PHONE_NUM.ERROR.' + result.message,
                                        {phonenumber: phoneNumber}
                                    );

                                    this.inviteForm.setFieldError('address', errMsg);
                                    resolve(OperationResult.getFailResult());
                                }
                            } else {
                                resolve(OperationResult.getSuccessResult());
                            }
                        });
                } else {
                    resolve(OperationResult.getSuccessResult());
                }
            });
        });
    }

    updateInviteState(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.validateInviteForm().then((validationResult) => {
                if (validationResult.isSuccess) {
                    const prevFormValues = clone(this._flowManager.inviteState.inviteMethodInfo);
                    const currentFormValues = this.inviteForm.getFormValues();

                    currentFormValues.addressType = this._flowManager.inviteState.inviteMethodInfo.addressType;

                    currentFormValues.visualJourneyAlias = this.visualJourneyAlias;

                    currentFormValues.clientLanguage = this.selectedClientLanguage;
                    currentFormValues.enableAudio = this.shouldStartWithAudio;
                    currentFormValues.enableVideo = this.isVideoSupportedMode ? this._enableVideo : undefined;
                    currentFormValues.enableMeasure = this.shouldStartWithMeasure;
                    currentFormValues.virtualBackground = this.selectedVirtualBackground;

                    this._flowManager.updateInviteMethod(currentFormValues);

                    const newFormValues = clone(this._flowManager.inviteState.inviteMethodInfo);

                    this._flowManager.inviteLogger.inviteMethodChangedByModal(prevFormValues, newFormValues);

                    currentFormValues.referralRegion = this.selectedReferralRegion;

                    resolve();
                } else {
                    reject({message: 'Invite method form is in invalid state', formError: true});
                }
            });
        });
    }

    resetInviteState(): void {
        this._flowManager.updateInviteMethod(new InviteMethodInfo());

        if (this.isLanguageSelectionEnabled) {
            const {inviteMethodInfo} = this._flowManager.inviteState;
            const {clientLanguage} = inviteMethodInfo;

            if (clientLanguage) {
                const clientLanguageToSelect =
                    clientLanguage && this.clientLanguages.find((language) => language.value === clientLanguage);

                if (clientLanguageToSelect) {
                    this.inviteForm.fields[this.inviteForm.fieldNames.selectedLanguage].setValue(
                        clientLanguageToSelect.name
                    );
                }
            }
        }

        // set the selected app localization
        if (this.isVideoApplicationEnabled && this.isVideoApplicationSelected) {
            const referralConfiguration = find(this.referralRegionList, {
                value: this._accountSettings.referralConfiguration
            });

            this.inviteForm.fields[this.inviteForm.fieldNames.referralRegion].setValue(referralConfiguration!.name);
        }
    }

    resetInviteForm(): void {
        this.rebuildFormInternal();

        if (this.isCountryCodeEnabled && this.isPhoneNumberEnabled) {
            this.initializeAccountCountryCode();
        }
    }

    rebuildForm(addressType: AddressTypesEnum): void {
        const currentFormValues = this.inviteForm.getFormValues();

        currentFormValues.addressType = addressType;

        this._flowManager.updateInviteMethod(currentFormValues);
        this.rebuildFormInternal();
    }

    @action
    private rebuildFormInternal(): void {
        this.inviteForm.removeFields();
        this.createFormFields(this.inviteForm);
    }

    @action
    setPhoneNumber(value: string): void {
        const {phoneNumber} = this.inviteForm.fieldNames;

        this.inviteForm.fields[phoneNumber].setValue(value);
    }

    @action
    setCountryCode(value: string): void {
        const countryCodeValue = sanitizeCountryCode(value);
        const {countryCode} = this.inviteForm.fieldNames;

        this.inviteForm.fields[countryCode].setValue(`+${countryCodeValue}`);
    }

    @action
    setCustomerRef(value: string): void {
        const {customerRef} = this.inviteForm.fieldNames;

        this.inviteForm.fields[customerRef].setValue(value);
    }

    private createFormFields(inviteForm: InviteMethodForm): void {
        const {inviteMethodInfo} = this._flowManager.inviteState;
        const {customerRef, selectedLanguage, phoneNumber, countryCode, coBrowsingInitialUrl} =
            this.inviteForm.fieldNames;

        this._flowManager.resetAddressField();

        if (this.isPhoneNumberEnabled && !this.isInviteByEmail) {
            inviteForm.createAddressField(this.isCountryCodeEnabled, this.addressType);
            inviteForm.fields[phoneNumber].setValue(inviteMethodInfo.phoneNumber || '');
        }

        if (this.isInviteByEmail) {
            inviteForm.createAddressField(this.isCountryCodeEnabled, this.addressType);
        }

        if (this.isCountryCodeEnabled && this.isPhoneNumberEnabled) {
            const countryCodeValue = inviteMethodInfo.countryCode || '';

            countryCodeValue
                ? inviteForm.fields[countryCode].setValue(countryCodeValue)
                : this.initializeAccountCountryCode();
        }

        if (this.isCustomerRefEnabled) {
            inviteForm.createCustomerRefField();
            inviteForm.fields[customerRef].setValue(inviteMethodInfo.customerRef);
        }
        if (this.isCoBrowsingEnabled) {
            inviteForm.createCoBrowsingInitialUrlField();
            inviteForm.fields[coBrowsingInitialUrl].setValue(get(this._accountSettings, 'coBrowsing.defaultURL'));
        }

        if (this.isLanguageSelectionEnabled) {
            inviteForm.createLanguageField();
            const clientLanguageToSelect =
                inviteMethodInfo.clientLanguage &&
                this.clientLanguages.find((clientLanguage) => clientLanguage.value === inviteMethodInfo.clientLanguage);

            if (clientLanguageToSelect) {
                inviteForm.fields[selectedLanguage].setValue(clientLanguageToSelect.name);
            }
        }

        if (this.isVisualJourneyEnabled && this.visualJourniesList.length) {
            inviteForm.createVisualJourneyField();

            const visualJourneyToSelect = this.visualJourniesList[0].name;

            this.inviteForm.fields[this.inviteForm.fieldNames.visualJourney].setValue(visualJourneyToSelect);
        }

        if (this.isVideoApplicationEnabled) {
            inviteForm.createReferralRegionField();

            const referralConfiguration = find(this.referralRegionList, {
                value: this._accountSettings.referralConfiguration
            });

            this.inviteForm.fields[this.inviteForm.fieldNames.referralRegion].setValue(referralConfiguration!.name);
        }

        if (this.isVirtualBackgroundEnabled && this.virtualBackgroundList.length) {
            inviteForm.createVirtualBackgroundField();

            const background = this.virtualBackgroundList.find((item) => item.value === this.selectedVirtualBackground);

            this.inviteForm.fields[this.inviteForm.fieldNames.virtualBackground].setValue(
                background?.name || this._flowManager.translate('REACT.INVITE.VIEW.SELECT_OPTION_NONE')
            );
        }
    }

    private initializeAccountCountryCode(countryCodeValue?: string | undefined) {
        const {countryCode} = this.inviteForm.fieldNames;
        const countryCodeNumber = countryCodeValue || this._accountSettings.defaultCountryCode;
        const accountDialCode = `+${countryCodeNumber}`;
        const country: ICountryInfo | undefined = find(this.countryList, {dial: accountDialCode});

        if (country && country.dial) {
            this.inviteForm.fields[countryCode].setValue(country.dial);
        }
    }

    private setAddressDetails() {
        const phoneNumber = this.isPhoneNumberEnabled ? this.inviteForm.fields.phoneNumber.value.toString() : '';

        const countryCode = this.isCountryCodeEnabled ? this.inviteForm.fields.countryCode.value.toString() : '';

        const address = calculatePhoneNumberValue(
            phoneNumber,
            countryCode,
            this.isPhoneNumberEnabled,
            this.isCountryCodeEnabled
        );

        this.inviteForm.fields[this.inviteForm.fieldNames.address].setValue(address);
        this._browserUtilsService.saveToLocalStorage('phoneNumber', phoneNumber);
        this._browserUtilsService.saveToLocalStorage('countryCode', countryCode);

        return {address: address, countryCode: countryCode, phoneNumber: phoneNumber};
    }
}
