'use strict';

import './main/ts-device-classification/index';
import './dashboard/ts-session-summary/index';

const dependencies = ['ts-device-classification-module', 'ts-session-summary-module'];

import {tsMultiSelectDirective} from './ts-multi-select/ts-multi-select.directive';
import {tsImageLoaderDirective} from './ts-image-loader/ts-image-loader.directive';
import {tsImageEditorDirective} from './ts-image-editor/ts-image-editor.directive';
import {tsVideoPlayerDirective} from './ts-video-player/ts-video-player.directive';
import {tsModalImageDirective} from './ts-modal-image/ts-modal-image.directive';
import {tsFloatingPanelDirective} from './ts-floating-panel/ts-floating-panel.directive';
import {tsToastDirective} from './ts-toast/ts-toast.directive';
import {tsAnnotationBarDirective} from './ts-annotation-bar/ts-annotation-bar.directive';
import {tsCountrySelectDirective} from './ts-country-select/ts-country-select.directive';
import {tsDashboardTopBar} from './dashboard/ts-dashboard-top-bar/ts-dashboard-top-bar.directive';
import {tsDashboardBottomBar} from './dashboard/ts-dashboard-bottom-bar/ts-dashboard-bottom-bar.directive';
import {tsEndMeeting} from './dashboard/ts-end-meeting/ts-end-meeting.directive';
import {tsMuteButton} from './dashboard/ts-mute-button/ts-mute-button.directive';
import {tsDashboardLeftBar} from './dashboard/ts-dashboard-left-bar/ts-dashboard-left-bar.directive';
import {tsSessionInfo} from './main/ts-session-info/ts-session-info.directive';
import {tsVideoPause} from './main/ts-video-pause/ts-video-pause.directive';
import {tsLiveVideo} from './main/ts-live-video/ts-live-video.directive';
import {tsWidgets} from './main/ts-widgets/ts-widgets.directive';
import {tsImageEditTools} from './main/ts-image-edit-tools/ts-image-edit-tools.directive';
import {tsVideoTools} from './main/ts-video-tools/ts-video-tools.directive';
import {tsMeetingModes} from './main/ts-meeting-modes/ts-meeting-modes.directive';
import {tsInitiationMeetingModes} from './main/ts-initiation-meeting-modes/ts-initiation-meeting-modes.directive';
import {tsRightBar} from './main/ts-right-bar/ts-right-bar.directive';
import {tsCaptureImage} from './main/ts-capture-image/ts-capture-image.directive';
import {tsRecognizeImage} from './main/ts-recognize-image/ts-recognize-image.directive';
import {tsNewScan} from './main/ts-new-scan/ts-new-scan.directive';
import {tsSoundToggle} from './main/ts-sound-toggle/ts-sound-toggle.directive';
import {tsLoader} from './ts-loader/ts-loader.directive';
import {tsSharingModeDirective} from './ts-sharing-mode/ts-sharing-mode.directive';
import {tsImageShareModesDirective} from './ts-image-share-modes/ts-image-share-modes.directive';
import {tsShareMultipleResources} from './ts-share-multiple-resources/ts-share-multiple-resources.directive';
import {tsResourceCheckbox} from './ts-resource-checkbox/ts-resource-checkbox.directive';
import {tsSnapshotReminder} from './ts-snapshot-reminder/ts-snapshot-reminder.directive';
import {tsVideoPlayerPopup} from './ts-video-player-popup/ts-video-player-popup.directive';
import {TsIdleMode} from './ts-idle-mode/TsIdleMode';
import {ConnectivityCheckModal} from '../_react_/states/connectivity/connectivity-check-modal/connectivityCheckModal';
import {tsDashboardTitle} from './dashboard/ts-dashboard-title/ts-dashboard-title.directive';
import {tsRoundedButtonDirective} from './ts-rounded-button/ts-rounded-button.directive';
import {tsCobrowsingControlDirective} from './ts-cobrowsing-control/ts-cobrowsing-control.directive';
import {LoginConfirmation} from '../_react_/states/login-new/components/login-confirmation/component';
import {StartWithModes} from '../_react_/components/start-with-modes/component';
import {tsFloatingToolbar} from './main/ts-floating-toolbar/ts-floating-toolbar';
import {FloatingToolbar} from '@techsee/techsee-ui-common/lib/floating-toolbar';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {SpeedtestModal} from './main/speedtest-modal/SpeedtestModal';
import {ModeSwitchModal} from '../_react_/components/mode-switch-confirmation-modal/component';
import {DashboardGuidance} from '../_react_/states/dashboard/dashboard-guidance/component';
import {FallbackModeModal} from '../_react_/components/fallback-mode/component';
import {Spinner} from '@techsee/techsee-ui-common/lib/spinner';
import {tsIdleLoader} from './ts-idle-loader/ts-idle-loader.directive';
import {LoginModal} from '../_react_/states/login-new/components/login-modal/component';
import {GuestView} from '../_react_/components/guest/views/view';
import {DashboardTopBar} from '../_react_/states/dashboard/dashboard-top-bar/index';
import {DashboardFooter} from '../_react_/states/dashboard/footer/FooterComponent';
import {SmartSolutionComponent} from '../_react_/states/dashboard/smart-solution/component';
import {SelfServiceComponent} from '../_react_/states/dashboard/self-service/component';
import {LeftBarComponent} from '../_react_/layouts/main-layout/left-bar-base/LeftBarComponent';
import GuidanceTooltip from '../_react_/states/invite-new/invite-wizard/components/agent-guidance/components/GuidanceTooltip';
import {AgentPerformanceWidget} from '../_react_/components/agent-performance/component';
import {SelectVideoPublisher} from '../_react_/components/select-video-publisher/component';
import {SimpleModal} from '../_react_/components/simple-modal/component';
import {WifiAnalyzer} from '../_react_/components/wifi-analyzer/component';
import {LookupNumber} from '../_react_/states/invite-new/invite-wizard/components/lookup-number/component';
import {MessageAlert} from '@techsee/techsee-ui-common/lib/message-alert';
import {SwitchButton} from '@techsee/techsee-ui-common/lib/forms/buttons/switch';
import {RecordingIndicator} from '../_react_/components/recording-indicator/component';
import {LabelWithIcon} from '@techsee/techsee-ui-common/lib/forms/labels/with-icon';
import {ScreenLoader} from '../_react_/components/screen-loader/screen-loader';
import {GuestViewSf} from '../_react_/components/guest/views/sf-view';
import {SessionEnded} from '../_react_/components/session-ended/session-ended';
import {MiniDashboardLoader} from '../_react_/components/mini-dashboard-loader/mini-dashboard-loader';
import {HistorySearchByTags} from '../_react_/components/search-by-tags/component';
import {InteractionSummaryButton} from '../_react_/components/interaction-summary/interaction-summary-button';
import {InteractionSummaryComponent} from '@techsee/techsee-ui-components/lib/interaction-summary';
import {InvitationEndedView} from '../_react_/states/invitation-ended/view';
import {VideoResolutionIndication} from '../_react_/components/video-resolution-indication/video-resolution-indication';
import {VjHistoryView} from '../_react_/states/vj-history/view';
import {WifiAnalyzerView} from '../_react_/states/wifi-analyzer-history/view';
import Copilot from '../_react_/components/copilot';

export default angular
    .module('app.components', dependencies)
    .directive('tsMultiSelect', tsMultiSelectDirective)
    .directive('tsImageLoader', tsImageLoaderDirective)
    .directive('tsVideoPlayerPopup', tsVideoPlayerPopup)
    .directive('tsImageEditor', tsImageEditorDirective)
    .directive('tsVideoPlayer', tsVideoPlayerDirective)
    .directive('tsModalImage', tsModalImageDirective)
    .directive('tsFloatingPanel', tsFloatingPanelDirective)
    .directive('tsToast', tsToastDirective)
    .directive('tsAnnotationBar', tsAnnotationBarDirective)
    .directive('tsCountrySelect', tsCountrySelectDirective)
    .directive('tsDashboardTopBar', tsDashboardTopBar)
    .directive('tsDashboardBottomBar', tsDashboardBottomBar)
    .directive('tsEndMeeting', tsEndMeeting)
    .directive('tsMuteButton', tsMuteButton)
    .directive('tsDashboardLeftBar', tsDashboardLeftBar)
    .directive('tsSessionInfo', tsSessionInfo)
    .directive('tsVideoPause', tsVideoPause)
    .directive('tsLiveVideo', tsLiveVideo)
    .directive('tsWidgets', tsWidgets)
    .directive('tsImageEditTools', tsImageEditTools)
    .directive('tsVideoTools', tsVideoTools)
    .directive('tsMeetingModes', tsMeetingModes)
    .directive('tsInitiationMeetingModes', tsInitiationMeetingModes)
    .directive('tsRightBar', tsRightBar)
    .directive('tsCaptureImage', tsCaptureImage)
    .directive('tsRecognizeImage', tsRecognizeImage)
    .directive('tsNewScan', tsNewScan)
    .directive('tsLoader', tsLoader)
    .directive('tsSharingMode', tsSharingModeDirective)
    .directive('tsImageShareModes', tsImageShareModesDirective)
    .directive('tsShareMultipleResources', tsShareMultipleResources)
    .directive('tsResourceCheckbox', tsResourceCheckbox)
    .directive('tsSnapshotReminder', tsSnapshotReminder)
    .directive('tsDashboardTitle', tsDashboardTitle)
    .directive('tsIdleMode', (reactDirective) => reactDirective(TsIdleMode))
    .directive('tsStartWithModes', (reactDirective) => reactDirective(StartWithModes))
    .directive('connectivityCheckModal', (reactDirective) => reactDirective(ConnectivityCheckModal))
    .directive('loginConfirmation', (reactDirective) => reactDirective(LoginConfirmation))
    .directive('tsRoundedButton', tsRoundedButtonDirective)
    .directive('tsCobrowsingControl', tsCobrowsingControlDirective)
    .directive('floatingToolbar', (reactDirective) => reactDirective(FloatingToolbar))
    .directive('speedtestModal', (reactDirective) => reactDirective(SpeedtestModal))
    .directive('modeSwitchModal', (reactDirective) => reactDirective(ModeSwitchModal))
    .directive('tsConfirmationModal', (reactDirective) => reactDirective(ConfirmationModal))
    .directive('dashboardGuidance', (reactDirective) => reactDirective(DashboardGuidance))
    .directive('tsFloatingToolbar', tsFloatingToolbar)
    .directive('fallbackModeModal', (reactDirective) => reactDirective(FallbackModeModal))
    .directive('spinner', (reactDirective) => reactDirective(Spinner))
    .directive('guestView', (reactDirective) => reactDirective(GuestView))
    .directive('tsIdleLoader', tsIdleLoader)
    .directive('loginModal', (reactDirective) => reactDirective(LoginModal))
    .directive('dashboardTopBar', (reactDirective) => reactDirective(DashboardTopBar))
    .directive('dashboardFooter', (reactDirective) => reactDirective(DashboardFooter))
    .directive('dashboardLeftBar', (reactDirective) => reactDirective(LeftBarComponent))
    .directive('smartSolution', (reactDirective) => reactDirective(SmartSolutionComponent))
    .directive('selfService', (reactDirective) => reactDirective(SelfServiceComponent))
    .directive('guidanceTooltip', (reactDirective) => reactDirective(GuidanceTooltip))
    .directive('tsSoundToggle', tsSoundToggle)
    .directive('tsAgentPerformance', (reactDirective) => reactDirective(AgentPerformanceWidget))
    .directive('tsLookupNumberModal', (reactDirective) => reactDirective(LookupNumber))
    .directive('tsSelectVideoPublisher', (reactDirective) => reactDirective(SelectVideoPublisher))
    .directive('tsSimpleModal', (reactDirective) => reactDirective(SimpleModal))
    .directive('tsWifiAnalyzer', (reactDirective) => reactDirective(WifiAnalyzer))
    .directive('tsSwitchButton', (reactDirective) => reactDirective(SwitchButton))
    .directive('tsMessageAlert', (reactDirective) => reactDirective(MessageAlert))
    .directive('recordingIndicator', (reactDirective) => reactDirective(RecordingIndicator))
    .directive('labelWithIcon', (reactDirective) => reactDirective(LabelWithIcon))
    .directive('screenLoader', (reactDirective) => reactDirective(ScreenLoader))
    .directive('guestViewSf', (reactDirective) => reactDirective(GuestViewSf))
    .directive('sessionEnded', (reactDirective) => reactDirective(SessionEnded))
    .directive('miniDashboardLoader', (reactDirective) => reactDirective(MiniDashboardLoader))
    .directive('historySearchByTags', (reactDirective) => reactDirective(HistorySearchByTags))
    .directive('interactionSummary', (reactDirective) => reactDirective(InteractionSummaryComponent))
    .directive('interactionSummaryButton', (reactDirective) => reactDirective(InteractionSummaryButton))
    .directive('invitationEndedView', (reactDirective) => reactDirective(InvitationEndedView))
    .directive('vjHistoryView', (reactDirective) => reactDirective(VjHistoryView))
    .directive('wifiAnalyzerView', (reactDirective) => reactDirective(WifiAnalyzerView))
    .directive('videoResolutionIndication', (reactDirective) => reactDirective(VideoResolutionIndication))
    .directive('copilot', (reactDirective) => reactDirective(Copilot));
