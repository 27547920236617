'use strict';

import Blowfish from 'egoroof-blowfish';

export class TsSessionData {
    constructor($localStorage, $sessionStorage, $q) {
        'ngInject';

        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.$q = $q;

        this.password = this.$localStorage.auth.toString();

        this.bf = new Blowfish(this.password); // only key isn't optional
    }

    setValue(key, value) {
        let storage = {};

        if (this.$sessionStorage.tsSessionData) {
            let encodedData = this.$sessionStorage.tsSessionData;

            if (!(encodedData instanceof Uint8Array)) {
                encodedData = this._objectToUint8Array(encodedData);
            }

            storage = JSON.parse(this.bf.decode(encodedData));
        }

        storage[key] = value;

        // Encode and save the data in the session storage
        this.$sessionStorage.tsSessionData = this.bf.encode(JSON.stringify(storage));

        return this.$q((resolve) => resolve());
    }

    getValue(key) {
        if (this.$sessionStorage.tsSessionData) {
            let encodedData = this.$sessionStorage.tsSessionData;

            if (!(encodedData instanceof Uint8Array)) {
                encodedData = this._objectToUint8Array(encodedData);
            }

            const decoded = JSON.parse(this.bf.decode(encodedData));

            return this.$q((resolve) => resolve(decoded[key]));
        }
    }

    _objectToUint8Array(obj) {
        const array = new Uint8Array(40);

        for (let index = 0; index <= array.length; index++) {
            array[index] = obj[index];
        }

        return array;
    }
}
