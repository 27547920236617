import React from 'react';
import {InviteGenericStep, InviteStepParams} from '../_InviteGenericStep';
import {IInviteMethodController} from '../../components/invite-method-form/controller';
import {IJoinOfflineController} from '../../components/join-offline-form/controller';
import {IFilter, IObservableController} from '../../components/observable-form/controller';
import {GeneralSettings, InviteMethodSettings} from '../../../../../models/AccountSettings';
import {action, computed, observable} from 'mobx';
import {IInviteFlowManager, InviteStepsEnum} from '../../../_contracts/InviteContracts';
import {IMainLayoutController} from '../../../../../layouts/main-layout/layout.controller';
import {IOfflineSessionsController} from '../../components/offline-sessions/controller';
import {IPendingRoomSessionsController} from '../../components/PendingRoomSessions/controller';
import {InviteInfoContent} from './content';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {IObservableSessionsController} from '../../components/observable-sessions/controller';
import {IDbUser} from '../../../../../services/AngularServices/AngularServices';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

const INVITE_NOTIFICATION_MESSAGE_TIMEOUT = 10000;

interface IUser {
    canObserve: boolean;
    accountId: string;
}

export enum NotificationStatus {
    SUCCESS = 'success',
    ERROR = 'error'
}

export type NotificationStatusType = Nullable<NotificationStatus>;

export class InviteInfoStepController extends InviteGenericStep {
    private _inviteMethodController: IInviteMethodController;

    private _joinOfflineController: IJoinOfflineController;

    private _observableController: IObservableController;

    private _accGeneralSettings: GeneralSettings;

    private _accInviteSettings: InviteMethodSettings;

    private _currentUser: IUser;

    private _dbUserService: IDbUser;

    private _notificationTimeout: any;

    private _browserUtilsService: IBrowserUtilsService;

    @observable private _isInProgress: boolean;

    @observable private _notificationMessage: {
        text: Nullable<string>;
        status: NotificationStatusType;
    };

    // eslint-disable-next-line max-params
    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        inviteMethodController: IInviteMethodController,
        joinOfflineController: IJoinOfflineController,
        offlineSessionsController: IOfflineSessionsController,
        PendingRoomSessionsController: IPendingRoomSessionsController,
        accGeneralSettings: GeneralSettings,
        accInviteSettings: InviteMethodSettings,
        domUtilsService: IDomUtilsService,
        observableController: IObservableController,
        observableSessionsController: IObservableSessionsController,
        currentUser: IUser,
        dbUserService: IDbUser,
        browserUtilsService: IBrowserUtilsService
    ) {
        const params: InviteStepParams = {
            stepType: InviteStepsEnum.InviteMethod,
            content: () =>
                React.createElement(InviteInfoContent, {
                    step: this,
                    inviteInfo: inviteMethodController,
                    joinOffline: joinOfflineController,
                    offlineSessions: offlineSessionsController,
                    WarmTransferSessions: PendingRoomSessionsController,
                    observableForm: observableController,
                    observableSessions: observableSessionsController
                }),
            className: 'invite-method-step',
            isLeftBarVisible: true
        };

        super(flowManager, mainLayoutController, domUtilsService, params);

        this._browserUtilsService = browserUtilsService;
        this._isInProgress = false;
        this._notificationMessage = {text: null, status: null};
        this._inviteMethodController = inviteMethodController;
        this._joinOfflineController = joinOfflineController;
        this._observableController = observableController;
        this._accGeneralSettings = accGeneralSettings;
        this._accInviteSettings = accInviteSettings;
        this._currentUser = currentUser;
        this._dbUserService = dbUserService;

        this.onNextClick = this.onNextClick.bind(this);
        this.onJoinOfflineClick = this.onJoinOfflineClick.bind(this);
        this.onJoinWtClick = this.onJoinWtClick.bind(this);
        this.resetInviteMethodForm = this.resetInviteMethodForm.bind(this);
        this.retrieveObservableRooms = this.retrieveObservableRooms.bind(this);
    }

    retrieveObservableRooms(filter?: IFilter) {
        return this._dbUserService.observableByAccount(this._currentUser.accountId, filter).then((res) => res.data);
    }

    @computed
    get isInProgress(): boolean {
        return this._isInProgress;
    }

    @computed
    get isOfflineSessionAvailable(): boolean {
        return this._accInviteSettings.accountOfflineSessions;
    }

    @computed
    get isWarmTransferSessionAvailable(): boolean {
        return this._accInviteSettings.accountPendingRoomSessions;
    }

    @computed
    get isInviteByEmailAvailable(): boolean {
        return this._accInviteSettings.allowSendByEmail;
    }

    @computed
    get isInviteBySmsAvailable(): boolean {
        return this._accInviteSettings.allowInviteBySms && !this._accInviteSettings.allowSendByWhatsApp;
    }
    @computed
    get isInviteByWhatsAppAvailable(): boolean {
        return this._accInviteSettings.allowSendByWhatsApp;
    }

    @computed
    get isDesktopSharingAvailable(): boolean {
        return this._accInviteSettings.allowDesktopSharing;
    }

    @computed
    get notificationMessageText(): Nullable<string> {
        return this._notificationMessage.text;
    }

    @computed
    get notificationMessageStatus(): NotificationStatusType {
        return this._notificationMessage.status;
    }

    @computed
    get isDesktopSharingMode(): boolean {
        return this.flowManager.startWithModesController.isDesktopSharingMode;
    }

    @computed
    get isOfflineMode(): boolean {
        return this.flowManager.startWithModesController.isOfflineMode;
    }

    @computed
    get isAgentHelperAvailable(): boolean {
        return !this.isDesktopSharingMode && this._accInviteSettings.showWizard;
    }

    @computed
    get hasNextSubStep(): boolean {
        const {enableNewInviteWifiStep, enableNewInviteSpeakersStep} = this._accGeneralSettings;

        return !(!enableNewInviteWifiStep && !enableNewInviteSpeakersStep);
    }

    get isObserveTabVisible() {
        return this._currentUser.canObserve && this._accInviteSettings.observationEnabled;
    }

    @computed
    get isOfflineSessionStarted() {
        return this.flowManager.inviteState.sessionInfo.isOffline;
    }

    @computed
    get appState() {
        return this.flowManager.appState;
    }

    @computed
    get showInviteTabs() {
        return (
            this._accInviteSettings.accountOfflineSessions ||
            this._accInviteSettings.allowDesktopSharing ||
            this._accInviteSettings.allowSendByEmail ||
            this._accInviteSettings.allowInviteBySms
        );
    }

    @computed
    get allowSearchOfflineSessionsById() {
        return this._accInviteSettings.allowSearchOfflineSessionsById;
    }

    @computed
    get currentAddressType(): AddressTypesEnum {
        return this.flowManager.inviteState.inviteMethodInfo.addressType;
    }

    @computed
    get nextText(): string {
        if (this.hasNextSubStep && !this.isOfflineMode && !this._inviteMethodController.isVisualJourneySelected) {
            return this.translate('REACT.COMMON.BUTTONS.NEXT');
        }
        const nextTextMap: any = {
            [AddressTypesEnum.EMAIL]: this.translate('REACT.EMAIL.SEND_EMAIL'),
            [AddressTypesEnum.SMS]: this.translate('REACT.SMS.SEND_SMS'),
            [AddressTypesEnum.WHATSAPP]: this.translate('REACT.WHATSAPP.SEND_WHATSAPP')
        };

        return nextTextMap[this.currentAddressType] || this.translate('REACT.SMS.SEND_SMS');
    }

    @action
    setInviteAddressType(addressType: AddressTypesEnum) {
        this._inviteMethodController.rebuildForm(addressType);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setActiveTab(index: number) {}

    resetInviteMethodForm() {
        this._inviteMethodController.resetInviteForm();
    }

    @action
    setNotificationMessage(message: Nullable<string>, status: NotificationStatusType) {
        this._notificationMessage.text = message;
        this._notificationMessage.status = status;

        this._notificationTimeout = setTimeout(() => {
            this._clearNotificationMessage();
        }, INVITE_NOTIFICATION_MESSAGE_TIMEOUT);
    }

    @action
    _clearNotificationMessage() {
        if (this._notificationTimeout) {
            clearTimeout(this._notificationTimeout);
            this._notificationTimeout = null;
        }

        this._notificationMessage.text = null;
        this._notificationMessage.status = null;
    }

    onNextClick(): PromiseLike<void> {
        this._clearNotificationMessage();
        this.setProgress(true);

        if (this._inviteMethodController.isVisualJourneySelected) {
            const isSentByEmail = this.currentAddressType === AddressTypesEnum.EMAIL;

            return (isSentByEmail ? this.flowManager.sendVisualJourneyEmail() : this.flowManager.sendVisualJourneySMS())
                .then(() => {
                    const successNotificationMessage = isSentByEmail
                        ? 'REACT.INVITE.VIEW.VISUAL_JOURNEY_EMAIL_SUCCESS'
                        : 'REACT.INVITE.VIEW.VISUAL_JOURNEY_SMS_SUCCESS';

                    this.setProgress(false);
                    this.setNotificationMessage(
                        this.translate(successNotificationMessage, {
                            selectedFlow: this._inviteMethodController.visualJourneyName
                        }),
                        NotificationStatus.SUCCESS
                    );
                })
                .catch((error) => {
                    if (error && error.status && error.status > 400) {
                        const errorNotificationMessage = isSentByEmail
                            ? 'REACT.INVITE.VIEW.VISUAL_JOURNEY_EMAIL_FAILED'
                            : 'REACT.INVITE.VIEW.VISUAL_JOURNEY_SMS_FAILED';

                        this.setNotificationMessage(this.translate(errorNotificationMessage), NotificationStatus.ERROR);
                    }

                    this.setProgress(false);
                });
        }

        return this.flowManager
            .startSession()
            .then(() => {
                this.setProgress(false);
                this._inviteMethodController.setCreatingSessionInProgress(false);

                if (this.isOfflineSessionStarted) {
                    this.setNotificationMessage(
                        this.translate('REACT.INVITE.VIEW.OFFLINE_SESSION_NOTIFICATION'),
                        NotificationStatus.SUCCESS
                    );
                }
            })
            .catch((err) => {
                this.setProgress(false);
                this._inviteMethodController.setCreatingSessionInProgress(false);
                this.flowManager.inviteLogger.failedToStartSession({err});
                const formError = err && err.formError;

                if (!formError) {
                    this.setNotificationMessage(
                        this.translate('REACT.INVITE.VIEW.START_SESSION_FAILED'),
                        NotificationStatus.ERROR
                    );
                }
            });
    }

    async onJoinOfflineClick(sessionId: string): Promise<void> {
        this.setProgress(true);

        try {
            await this.flowManager.joinOfflineSession(sessionId);

            this.setProgress(false);
        } catch (error) {
            this.setProgress(false);

            throw error;
        }
    }

    async onJoinWtClick(sessionLink: string): Promise<void> {
        this.setProgress(true);
        try {
            await this.flowManager.joinWtSession(sessionLink);
            this.setProgress(false);
        } catch (error) {
            this.setProgress(false);
            throw error;
        }
    }

    @action
    private setProgress(state: boolean = false) {
        this._isInProgress = state;
    }
}
