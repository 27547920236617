/*
 * This controller is responsible to load initial data, decide which of "flows" should be used (new or old) and load it.
 */

import get from 'lodash/get';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import {Flavors} from '../../services/ts-ui-flavor/ts-ui-flavor.service';
import {getRootStore} from '../../_react_/app.bootstrap';
import {sendSentryLog} from '../../error-reporting';
import {InvitationEndedSessionTypes} from '../../_react_/states/invitation-ended/controller';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

/*
    Before login, the only clue of which account is used is the alias. Until account is known,
        we rely on the alias used during login. After the account is set, the determine factor is the account setting.
        That way, in case an alias is not used (so the login is the "old" flavor), the invite and following states within
    the session will use the new flavor.
*/
function _getAccountFlavor(accountData, isMobile, isTablet) {
    if (isMobile && !isTablet) {
        return Flavors.Default;
    }

    return get(accountData, 'protectedSettings.enableNewInvite') ? Flavors.NewInvite : Flavors.Default;
}

export default class InviteRootController {
    constructor(
        $state,
        $scope,
        $localStorage,
        tsStateHelper,
        currentUser,
        accountData,
        UiFlavorNg,
        tsUrlUtils,
        ROLES,
        auth,
        db,
        eventLog
    ) {
        'ngInject';

        this.db = db;
        this._initState(getRootStore().browserUtilsService);
        this._tsStateHelper = tsStateHelper;
        this.urlUtils = tsUrlUtils;
        this.auth = auth;
        this.eventLog = eventLog;

        this.environmentDetect = getRootStore().environmentService;
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;
        this.jsApiService = getRootStore().jsApiService;

        if (currentUser.role === ROLES.GUEST_OBSERVER) {
            const roomId = this.urlUtils.getParamValue('roomId'),
                g = this.urlUtils.getParamValue('g'),
                observe = this.urlUtils.getParamValue('observe'),
                roomCode = this.urlUtils.getParamValue('roomCode');

            if (!(roomId || g || roomCode) || !observe) {
                this.auth.clearAuthToken();

                return tsStateHelper.safeGo('invitationEnded');
            }
        }

        const exposePostMessageApi = get(accountData, 'protectedSettings.exposePostMessageApi');
        const accountSettingsJsApi = {
            timezone: get(accountData, 'settings.timezone'),
            language: get(accountData, 'settings.language')
        };

        if (exposePostMessageApi && exposePostMessageApi.enabled) {
            this.jsApiService.init(null, assign(exposePostMessageApi, accountSettingsJsApi));
            const ott = this.urlUtils.getParamValue('ott');

            const loginCallbackResult = $localStorage.loginCallbackResult;
            const authId =
                $state.params.authId ||
                this.urlUtils.getParamValue('authId') ||
                ($localStorage.auth && $localStorage.auth.authId);

            if (loginCallbackResult) {
                this.jsApiService.SSOLoginSuccess(authId);
            } else if (loginCallbackResult === false) {
                this.jsApiService.SSOLoginFailure(authId);
            }

            if (ott) {
                this.jsApiService.OTTLoginSuccess();
            }
        }

        UiFlavorNg.service.isNewFlavorAndSupported().then((supported) => {
            $scope.$watch(
                () => $state.$current.name,
                (newState, oldState) => {
                    if (oldState.match(/invite\./i) && newState.match(/invite$/i)) {
                        this.redirectToInviteState(supported, currentUser, ROLES, $localStorage, tsStateHelper);
                    }
                }
            );

            if ($state.current.name.match(/invite$/)) {
                this.redirectToInviteState(supported, currentUser, ROLES, $localStorage, tsStateHelper);
            }
        });
    }

    isEnv(platform) {
        return this.environmentDetect.isEnvironment(platform, this.displayTabletAsDesktop);
    }

    _initState(browserUtilsService) {
        browserUtilsService.saveToSessionStorage('screenshots', []);
        browserUtilsService.removeFromSessionStorage('socketConfig');
        browserUtilsService.removeFromSessionStorage('usingApplication');
        browserUtilsService.removeFromSessionStorage('sessionStartedTimestamp');
        browserUtilsService.removeFromSessionStorage('shouldNotDisplayAgentPerformance');
    }

    redirectToInviteState(isNewFlavorAndSupported) {
        const goto = isNewFlavorAndSupported ? 'invite.new' : 'invite.old';
        const accountSocketService = getRootStore().accountSocketService;

        accountSocketService.joinAccountChannel().catch((err) => sendSentryLog(err));

        const roomId = this.urlUtils.getParamValue('roomId'),
            g = this.urlUtils.getParamValue('g'),
            roomCode = this.urlUtils.getParamValue('roomCode'),
            observe = this.urlUtils.getParamValue('observe'),
            emb = this.urlUtils.getParamValue('emb'),
            mini = this.urlUtils.getParamValue('mini'),
            sw = this.urlUtils.getParamValue('sw'),
            audio = this.urlUtils.getParamValue('audio'),
            prg = this.urlUtils.getParamValue('prg'),
            is = this.urlUtils.getParamValue('is'),
            r = this.urlUtils.getParamValue('r'),
            startDate = this.urlUtils.getParamValue('startDate'),
            endDate = this.urlUtils.getParamValue('endDate');

        if (prg) {
            return this.handleWarmTransferRequest(roomId, g, roomCode, audio, observe, emb, mini, sw, prg);
        }

        if (is) {
            if (is.toLowerCase() === 'vjhistory') {
                this._tsStateHelper.safeGo('vjhistory', {emb, r, startDate, endDate, is, roomId});

                return;
            }
        }

        if (roomId || g || roomCode) {
            this._tsStateHelper.safeGo('join', {roomId, shortId: g, roomCode, audio, observe, emb, mini, sw});

            return;
        }

        this._tsStateHelper.safeGo(goto);
    }

    static currentUser(
        $state,
        $localStorage,
        $stateParams,
        tsStateHelper,
        $q,
        db,
        $window,
        ROLES,
        tsQuickLauncher,
        auth
    ) {
        'ngInject';

        return ($stateParams.ott ? auth.loginWithOneTimeToken($stateParams.ott) : Promise.resolve())
            .then(() => tsQuickLauncher.ensureToken())
            .then(() => db.User.find('current'))
            .then((user) => {
                const isSysadminOrSuperAdmin = user.role === ROLES.SYSADMIN || user.role === ROLES.SUPERADMIN;
                const userAgent = $window.navigator.userAgent;
                const accountId = user.accountId;
                const accounts = user.accounts;

                if (!accountId && !isEmpty(accounts)) {
                    return tsStateHelper.safeGo('login', {multiUser: user});
                }

                const resultPromise = isSysadminOrSuperAdmin
                    ? Promise.resolve({})
                    : TsEnvironmentDetect.getBrowserClientHints().then((browserClientHints) => {
                          const clientHints = btoa(JSON.stringify(browserClientHints));

                          return db.SupportedBrowsers.isSupportedByAccount(accountId, {
                              params: {ua: userAgent, clientHints}
                          });
                      });

                return resultPromise.then((response) => {
                    if (!isEmpty(response.data)) {
                        return tsStateHelper.safeGo('browserNotSupported', {
                            browserInfo: response.data,
                            userName: user.firstName
                        });
                    }

                    const ssoAuthId = $state.params.authId;
                    const guestObserver = user.role === ROLES.GUEST_OBSERVER;

                    if (user.requirePasswordChange && !ssoAuthId && !guestObserver) {
                        tsStateHelper.safeGo('changePassword');
                    }

                    return user;
                });
            })
            .catch((err) => {
                tsStateHelper.safeGo('login', $stateParams);

                // Reject to prevent this state from loading
                return $q.reject(err);
            });
    }

    static accountData(
        db,
        currentUser,
        ROLES,
        tsUrlUtils,
        $localStorage,
        tsStateHelper,
        tsInterfaceHelper,
        UiFlavorNg
    ) {
        'ngInject';

        if (!currentUser.accountId) {
            return [];
        }

        const environmentDetect = getRootStore().environmentService;
        const displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

        return db.Account.find(currentUser.accountId, {bypassCache: true}).then((account) => {
            const isTablet = environmentDetect.isTablet(displayTabletAsDesktop) || tsUrlUtils.getParamValue('tablet');

            UiFlavorNg.service.setFlavor(_getAccountFlavor(account, environmentDetect.isPhone(), isTablet));

            if (currentUser.role === ROLES.KNOWLEDGE_EXPERT && !account.protectedSettings.knowledgeExpertRunSessions) {
                tsUrlUtils.setParamValue('offline', true);
                tsStateHelper.safeGo('dashboard.main', {offline: true});
            }

            if (isTablet && !displayTabletAsDesktop) {
                getRootStore().browserUtilsService.saveToLocalStorage('displayTabletAsDesktop', true);
                location.reload(true);
            }

            tsInterfaceHelper.setInterfaceType(account.protectedSettings.enableNewDashboardUI);
            tsInterfaceHelper.setHeaderFooterLeftbarType(account.protectedSettings.newHeaderFooterLeftbar);

            if (account?.protectedSettings?.visualJourney?.useFlowManagementV2) {
                return db.Vj.getFlows({params: {accountId: currentUser.accountId, statuses: 'production'}})
                    .then((res) => {
                        account.protectedSettings.visualJourney.available =
                            res?.data?.flows?.map((flow) => ({
                                name: flow?.metaData[0]?.name,
                                alias: flow?.urlAlias
                            })) || [];

                        return account;
                    })
                    .catch(() => account);
            }

            return account;
        });
    }

    handleWarmTransferRequest(roomId, g, roomCode, audio, observe, emb, mini, sw, prg) {
        return this.db.PendingRoom.activatePendingRoom({data: {pendingRoomGuid: prg}})
            .then((result) => {
                this.urlUtils.setParamValue('roomId', result?.data?.roomId);
                this.urlUtils.setParamValue('shortId', result?.data?.g);

                this.eventLog.warmTransferRoomActivationSucceeded({
                    statusCode: result?.statusCode,
                    statusMessage: result?.statusMessage
                });
                this._tsStateHelper.safeGo('join', {
                    roomId,
                    shortId: g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    sw,
                    prg
                });
            })
            .catch((result) => {
                this.eventLog.warmTransferRoomActivationFailed({
                    statusCode: result?.statusCode,
                    statusMessage: result?.statusMessage
                });
                this._tsStateHelper.safeGo('invitationEnded', {
                    err: result?.status,
                    sessionType: InvitationEndedSessionTypes.warmTransfer
                });
            });
    }
}
