/* eslint-disable max-classes-per-file */
import {serializable} from 'serializr';
import {observable} from 'mobx';
import {MeetingMode, AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {ConnectionState, ConnectionStatesEnum, ConnectionTypesEnum} from '../services/Session/SessionContracts';
import {ClientLocationInfo} from '../states/dashboard/dashboard-top-bar/components/location-info/controller';
import {VideoFilterType} from '@techsee/techsee-common/lib/constants/account.constants';

export class NetworkInfo {
    @serializable @observable connectionType: ConnectionTypesEnum = ConnectionTypesEnum.NETWORK;

    @serializable @observable downlinkMax: number = 0;

    static fromDataObject(dataObject: any) {
        const networkInfo = new NetworkInfo();

        if (typeof dataObject === 'object') {
            const downlinkValue = parseInt(dataObject.downlinkMax, 10);

            networkInfo.downlinkMax = !isNaN(downlinkValue) ? downlinkValue : 0;
            networkInfo.connectionType =
                dataObject.connectionType && dataObject.connectionType.toUpperCase
                    ? dataObject.connectionType.toUpperCase()
                    : ConnectionTypesEnum.NETWORK;
        } else if (typeof dataObject === 'string') {
            if (Object.values(ConnectionTypesEnum).includes(dataObject.toUpperCase() as ConnectionTypesEnum)) {
                networkInfo.connectionType = dataObject.toUpperCase() as ConnectionTypesEnum;
            }
        }

        return networkInfo;
    }
}

export class InviteMethodInfo {
    @serializable @observable clientLanguage: string = '';

    @serializable @observable customerRef: string = '';

    @serializable @observable address: string = '';

    @serializable @observable countryCode?: string = '';

    @serializable @observable phoneNumber?: string = '';

    @serializable @observable visualJourney: string = '';

    @serializable @observable visualJourneyAlias: string = '';

    @serializable @observable addressType: AddressTypesEnum = AddressTypesEnum.SMS;

    @serializable @observable enableAudio: boolean = false;

    @serializable @observable enableVideo?: boolean = false;

    @serializable @observable enableMeasure: boolean = false;

    @serializable @observable referralRegion: string = '';

    @serializable @observable virtualBackground: VideoFilterType = VideoFilterType.NONE;
}

export class SessionInfo {
    @serializable @observable sessionId: string = '';

    @serializable @observable sessionRoomId: string = '';

    @serializable @observable sessionLink: string = '';

    @serializable @observable clientJoinedSession: boolean = false;

    @serializable @observable isSessionActive: boolean = false;

    @serializable @observable isOffline: boolean = false;

    @serializable @observable isDesktopSharing: boolean = false;

    @serializable @observable meetingMode?: MeetingMode;

    @serializable @observable inviteByCode?: string;

    @serializable @observable referralRegion?: string = '';
}

export abstract class SessionStateBase {
    inviteMethodInfo: InviteMethodInfo = new InviteMethodInfo();

    sessionInfo: SessionInfo = new SessionInfo();

    clientNetworkInfo: NetworkInfo = new NetworkInfo();

    @observable clientMobileType: string = '';

    @observable clientMobileBrowser: string = '';

    @observable clientMobileOsName: string = '';

    @observable clientMobileOsVersion: string = '';

    @observable usingApplication: boolean = false;

    @observable clientLocationInfo: ClientLocationInfo = new ClientLocationInfo();

    @observable connectionState: ConnectionState = ConnectionStatesEnum.OFFLINE;
}
