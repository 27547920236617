'use strict';

import dashboard from './dashboard/dashboard.module.js';
import invite from './invite/invite-root.module.js';
import login from './login/login-root.module';
import join from './join/join.module.js';
import loginCallback from './login-callback/login-callback.module.js';
import changePassword from './change-password/change-password.module.js';
import browserNotSupported from './browser-not-supported/browser-not-supported.module.js';
import guest from '../_react_/states/guest/guest-state.module';
import multipartyDashboard from '../_react_/states/multipartyDashboard/multipartyDashboard.module';
import sessionEnded from '../_react_/states/session-ended/session-ended.module';
import reactivateAccount from '../_react_/states/sso-action/reactivate-account.module';
import revokeAccount from '../_react_/states/sso-action/revoke-account.module';
import invitationEnded from '../_react_/states/invitation-ended/invitation-ended.module';
import connectivityCheck from '../_react_/states/connectivity/connectivity-check.module';
import connectivityStandalone from '../_react_/states/connectivity-standalone/connectivity-standalone.module';
import embeddedDashboardGeneralError from './embedded-dashboard-general-error/embedded-dashboard-general-error.module.js';
import vjHistory from '../_react_/states/vj-history/vj-history.module';
import wifiAnalyzerHistory from '../_react_/states/wifi-analyzer-history/wifi-analyzer-history.module';

export default angular.module('app.states', [
    dashboard.name,
    multipartyDashboard.name,
    invite.name,
    login.name,
    join.name,
    loginCallback.name,
    changePassword.name,
    browserNotSupported.name,
    guest.name,
    invitationEnded.name,
    connectivityCheck.name,
    connectivityStandalone.name,
    embeddedDashboardGeneralError.name,
    sessionEnded.name,
    vjHistory.name,
    wifiAnalyzerHistory.name,
    reactivateAccount.name,
    revokeAccount.name
]);
