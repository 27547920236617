/* eslint-disable max-classes-per-file */
import {action, computed, observable} from 'mobx';
import {IReactComponent} from 'mobx-react';
import {ReactElement} from 'react';

import {ITranslate} from '../../../services/LocalizationService';
import {IAgentInfoController} from '../agent-info-menu/controller';

export interface ILeftBarItemData {
    isActive: boolean;
    isSelected: boolean;
    isDisabled: boolean;
    isHovered: boolean;
    isObserverConnected?: boolean;
    content?: IReactComponent;
    uid: string;
    hideContent?: boolean;

    tooltip: () => string;
    icon: () => string | ReactElement;
    onItemClicked: () => void;
}

export class LeftBarItemData {
    @observable isActive: boolean = false;

    @observable isSelected: boolean = false;

    @observable isDisabled: boolean = false;

    @observable isObserverConnected?: boolean;

    @observable isHovered: boolean = false;

    @observable content?: IReactComponent;

    @observable tooltip?: () => string = () => '';

    //The icon should be only icons that supported in icons scss.
    @observable icon: () => string = () => '';

    @observable onItemClicked: (item: ILeftBarItemData) => void = () => {};
}

export enum AlignEnum {
    BOTTOM = 'bottom',
    TOP = 'top'
}

export enum DashboardSectionTypes {
    MODES = 'modes',
    AGENT_ASSIST = 'agent-assist',
    HELP = 'help',
    OBSERVER = 'observer',
    APP_LAUNCHER = 'app-launcher',
    TWO_WAY = 'two-way'
}

export enum WizardSectionTypes {
    INVITE = 'invite',
    HISTORY = 'history',
    APP_LAUNCHER = 'app-launcher',
    HELP = 'help',
    SWITCH_USER = 'switch-user',
    VJ_HISTORY_FILTER = 'vj_history_filter',
    WIFI_ANALYZER_HISTORY = 'wifi_analyzer_history'
}

export interface ILeftBarSection {
    leftBarItems: ILeftBarItemData[];
    alignItems: AlignEnum;
    displaySeparator: boolean;
    sectionType: DashboardSectionTypes | WizardSectionTypes;
    agentInfoController: IAgentInfoController | null;
    windowWidth: number;
}

export enum LeftBarItemTypesEnum {
    modesSection = 0,
    observer = 1,
    agentAssist = 2,
    appLauncher = 3,
    helpSection = 4
}

export class LeftBarSection implements ILeftBarSection {
    private _agentInfoController?: IAgentInfoController;

    @observable private _alignItems: AlignEnum;

    @observable private _displaySeparator: boolean;

    @observable private _leftBarItems: ILeftBarItemData[];

    @observable private _sectionType: DashboardSectionTypes | WizardSectionTypes;

    @observable private _windowWidth: number;

    constructor(
        alignItems: AlignEnum,
        displaySeparator: boolean,
        leftBarItems: ILeftBarItemData[],
        sectionType: DashboardSectionTypes | WizardSectionTypes,
        agentInfoController?: IAgentInfoController
    ) {
        this._alignItems = alignItems;
        this._displaySeparator = displaySeparator;
        this._leftBarItems = observable.array(leftBarItems, {deep: true});
        this._sectionType = sectionType;
        this._agentInfoController = agentInfoController;
        this._windowWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            this.setWindowWidth();
        });
    }

    @computed
    get windowWidth() {
        return this._windowWidth;
    }

    @action
    setWindowWidth() {
        this._windowWidth = window.innerWidth;
    }

    get agentInfoController() {
        return this._agentInfoController || null;
    }

    @computed
    get leftBarItems() {
        return this._leftBarItems;
    }

    @computed
    get sectionType() {
        return this._sectionType;
    }

    @computed
    get alignItems() {
        return this._alignItems;
    }

    @computed
    get displaySeparator() {
        return this._displaySeparator;
    }

    @action
    setDisplaySeparator(value: boolean) {
        this._displaySeparator = value;
    }
}

interface ILeftBarControllerBase {
    readonly translate: ITranslate;
    leftBarSections: ILeftBarSection[];
    getHeaderComponent(
        itemData: ILeftBarItemData,
        sectionType: DashboardSectionTypes | WizardSectionTypes
    ): ReactElement;
    insideContent(): void;
    cleanSelectedByItem(item: ILeftBarItemData): void;
    getLeftBarItems(): void;
    executeItem(
        predicat: (item: ILeftBarItemData) => boolean,
        filterBySection: (section: ILeftBarSection) => boolean,
        executeCb: (item: ILeftBarItemData) => void
    ): ILeftBarItemData[];
}

export abstract class LeftBarControllerBase implements ILeftBarControllerBase {
    private readonly _translate: ITranslate;

    @observable abstract _leftBarSections: ILeftBarSection[];

    @observable _isInsideContent: boolean = false;

    constructor(translate: ITranslate) {
        this._translate = translate;

        this.onLeftBarItemClicked = this.onLeftBarItemClicked.bind(this);
        this.insideContent = this.insideContent.bind(this);
        this.cleanSelectedStates = this.cleanSelectedStates.bind(this);
        this.cleanSelectedByItem = this.cleanSelectedByItem.bind(this);
    }

    abstract getHeaderComponent(
        itemData: ILeftBarItemData,
        sectionType: DashboardSectionTypes | WizardSectionTypes
    ): ReactElement;

    @computed
    get leftBarSections() {
        return this._leftBarSections;
    }

    get translate() {
        return this._translate;
    }

    executeItem(
        predicat: (item: ILeftBarItemData) => boolean,
        filterBySection: (section: ILeftBarSection) => boolean,
        executeCb: (item: ILeftBarItemData) => void
    ): ILeftBarItemData[] {
        const foundItems: ILeftBarItemData[] = [];

        this.leftBarSections.forEach((section: ILeftBarSection) => {
            if (filterBySection(section)) {
                section.leftBarItems.forEach((item: ILeftBarItemData) => {
                    if (predicat(item)) {
                        foundItems.push(item);
                        executeCb(item);
                    }
                });
            }
        });

        return foundItems;
    }

    abstract cleanSelectedStates(): void;

    @action
    cleanSelectedByItem(item: ILeftBarItemData) {
        item.isSelected = false;
    }

    @action
    insideContent(): void {
        this._isInsideContent = true;

        setTimeout(() => (this._isInsideContent = false));
    }

    @action
    onMouseOver(item: ILeftBarItemData, isEnter: boolean) {
        item.isHovered = isEnter;
    }

    abstract getLeftBarItems(): ILeftBarSection[];

    abstract onLeftBarItemClicked(
        menuItem: ILeftBarItemData,
        sectionType: DashboardSectionTypes | WizardSectionTypes
    ): void;
}
