import {inject, IReactComponent} from 'mobx-react';
import {WifiAnalyzerFilter} from './WifiAnalyzerFilter';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.wifiAnalyzerFilterController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const WifiAnalyzerFilterComponent = inject(mapStoresToProps)(WifiAnalyzerFilter) as IReactComponent;

export default WifiAnalyzerFilterComponent;
