import {action} from 'mobx';

/**
 * The order of registerWebRTCAdapters and DetectWebRtcService is important.
 * Because registerWebRTCAdapters imports IE shim file if needed, and it is required to be able to detect WebRTC in IE.
 **/
//TODO - Infra: Need to put WebRTC detection inside media-service-itself, and remove this lines that are error prone.
import {registerWebRTCAdapters} from '@techsee/techsee-media-service/lib/MediaUtils/Compatibility';
registerWebRTCAdapters();
import {
    DETECT_RTC_ADVISED_TIMEOUT,
    DetectWebRtcService,
    IWebRtcDetector
} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {EVENT_SOURCES, LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {
    IBrowserUtilsService,
    BrowserUtilsService
} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {DomUtilsService, IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {ScreenType} from '@techsee/techsee-common/lib/constants/visual-journey.constants';

import {
    IAuthService,
    IDbCustomStrings,
    IDbSms,
    IDbEmail,
    IDbSso,
    IDbSystemInfo,
    IDbRooms,
    IDbShortUrl,
    IDbEventLog,
    IDbAccount,
    IDbHistory,
    IDbVideoRecordings,
    IDbUser,
    IDbObservation,
    IDbSmart,
    IDbReports,
    IDbLocation,
    IDbResource,
    IDbOtt,
    IDbScanner
} from './services/AngularServices/AngularServices';

import {IApplicationService, ApplicationService} from './services/ApplicationService';
import {ILocalizationService, LocalizationService} from './services/LocalizationService';
import {IRedirectionService, RedirectionService} from './services/RedirectionService';
import {ITechseeSocketManager, TechseeSocketManager} from '@techsee/techsee-client-infra/lib/infra/SocketManager';
import {IEventLogsService, EventLogsService} from './services/EventsLogService';
import {AppState} from './app.state';
import {IUiFlavorService, UiFlavorService} from '../services/ts-ui-flavor/ts-ui-flavor.service';
import {DbSmsAdapter} from './services/AngularServices/SmsAdapter';
import {DbHistoryAdapter} from './services/AngularServices/HistoryAdapter';
import {DbUserAdapter} from './services/AngularServices/UserAdapter';
import {DbSmartAdapter} from './services/AngularServices/SmartAdapter';
import {DbResourceAdapter} from './services/AngularServices/ResourceAdapter';
import {IAppBootstrapParams} from './app.contracts';
import {IAuthExpiryService, AuthExpiryService} from '../services/ts-auth-expiry/ts-auth-expiry.service';
import {IMeasureService, MeasureService} from '../services/ts-measure/ts-measure.service';
import {ChatApi} from '../services/ts-chat-api/ChatApi';
import {createFieldModel} from './app.bootstrap';
import {AccountSocketService, IAccountSocketService} from './services/AccountSocket/AccountSocketService';
import {SpeedtestService, ISpeedtestService} from './services/SpeedtestService';
import {JsApiService, IJsApiService} from './services/JsApiService';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {DbEmailAdapter} from './services/AngularServices/EmailAdapter';
import {DbVideoRecordings} from './services/AngularServices/VideoRecordingsAdapter';
import {DbSso} from './services/AngularServices/SsoAdapter';
import {DbObservationAdapter} from './services/AngularServices/observationAdapter';
import {TsTimedOperationService} from '../services/ts-timed-operation/ts-timed-operation.service';
import {IVisibilityChange, TsVisibilityChange} from '../services/ts-visibility-change/ts-visibility-change.service';
import {
    IInteractionSummaryService,
    InteractionSummaryService
} from '@techsee/techsee-client-infra/lib/services/InteractionSummaryService';
import moment from 'moment';
import {DbScannerAdapter} from './services/AngularServices/ScannerAdapter';
import {IWifiAnalyzerService, WifiAnalyzerService} from './services/AngularServices/WifiAnalyzerService';

export interface IAppRootStore {
    initRootServices(): void;
    getDateFormattedByAcc(date: string, format: string): string;
    getDateFormattedByAccWifi(date: string, format: string): string;

    readonly globals: {
        window: Window;
    };

    readonly appState: AppState;
    readonly socketManager: ITechseeSocketManager;
    readonly applicationService: IApplicationService;
    readonly browserUtilsService: IBrowserUtilsService;
    readonly localizationService: ILocalizationService;
    readonly redirectionService: IRedirectionService;
    readonly authService: IAuthService;
    readonly dbSmsService: IDbSms;
    readonly dbEmailService: IDbEmail;
    readonly dbSsoService: IDbSso;
    readonly dbScannerService: IDbScanner;
    readonly dbSmartService: IDbSmart;
    readonly dbRoomsService: IDbRooms;
    readonly dbResourceService: IDbResource;
    readonly dbLocationService: IDbLocation;
    readonly dbObservationService: IDbObservation;
    readonly dbReportsService: IDbReports;
    readonly dbHistoryService: IDbHistory;
    readonly dbVideoRecordingsService: IDbVideoRecordings;
    readonly dbUserService: IDbUser;
    readonly dbShortUrlService: IDbShortUrl;
    readonly dbOttService: IDbOtt;
    readonly dbAccountService: IDbAccount;
    readonly eventsLogService: IEventLogsService;
    readonly domUtilsService: IDomUtilsService;
    readonly environmentService: ITsEnvironmentDetect;
    readonly displayTabletAsDesktop: boolean;
    readonly uiFlavorService: IUiFlavorService;
    readonly authExpiryService: IAuthExpiryService;
    readonly measureService: IMeasureService;
    readonly chatApi: any;
    readonly speedtestService: ISpeedtestService;
    readonly wifiAnalyzerService: IWifiAnalyzerService;
    readonly webRtcDetector: IWebRtcDetector;
    readonly mobileClientURL: string;
    readonly accountSocketService: IAccountSocketService;
    readonly jsApiService: IJsApiService;
    readonly timedOperationService: TsTimedOperationService;
    readonly visibilityChangeService: IVisibilityChange;
    readonly interactionSummaryService: IInteractionSummaryService;
    readonly safeApply: any;
    readonly interactionSummaryTranslated: any;
}

export class AppRootStore implements IAppRootStore {
    //Global window
    private _window: Window;
    //Angular services
    private _authService: IAuthService;
    private _tsUrlConfig: any;
    private _safeApply: any;
    private _tsLocaleHelper: any;
    private _dbSmsService?: IDbSms;
    private _dbEmailService?: IDbEmail;
    private _dbScannerService?: IDbScanner;
    private _dbSsoService?: IDbSso;
    private _dbRoomsService?: IDbRooms;
    private _dbResourceService?: IDbResource;
    private _dbLocationService?: IDbLocation;
    private _dbReportsService?: IDbReports;
    private _dbHistoryService?: IDbHistory;
    private _dbVideoRecordingsService?: IDbVideoRecordings;
    private _dbUserService?: IDbUser;
    private _dbObservationService?: IDbObservation;
    private _dbSmartService?: IDbSmart;
    private _dbShortUrlService?: IDbShortUrl;
    private _dbOttService?: IDbOtt;

    private _dbService: {
        System: IDbSystemInfo;
        CustomStrings: IDbCustomStrings;
        Rooms: IDbRooms;
        ShortUrl: IDbShortUrl;
        EventLog: IDbEventLog;
        Account: IDbAccount;
        History: IDbHistory;
        Scanner: IDbScanner;
        VideoRecordings: IDbVideoRecordings;
        Sso: IDbSso;
        Reports: IDbReports;
        Location: IDbLocation;
        Resource: IDbResource;
        OTT: IDbOtt;
    };

    private _eventsLogService?: IEventLogsService;
    private _applicationService?: IApplicationService;
    private _browserUtilsService?: IBrowserUtilsService;
    private _environmentService: ITsEnvironmentDetect;
    private _localizationService?: ILocalizationService;
    private _redirectionService?: IRedirectionService;
    private _domUtilsService?: IDomUtilsService;
    private _uiFlavorService?: IUiFlavorService;
    private _authExpiryService?: IAuthExpiryService;
    private _measureService?: IMeasureService;
    private _chatApi?: any;
    private _speedtestService?: ISpeedtestService;
    private _wifiAnalyzerService?: IWifiAnalyzerService;
    private _webRtcDetector?: IWebRtcDetector;
    private _socketManager?: ITechseeSocketManager;
    private _accountSocketService?: IAccountSocketService;
    private _jsApiService?: IJsApiService;
    private _socketUrl: string;
    private _mobileClientURL: string;
    private _timedOperation?: TsTimedOperationService;
    private _visibilityChangeService?: IVisibilityChange;
    private _appState?: AppState;
    private _interactionSummaryService?: IInteractionSummaryService;

    constructor(bootStrapParams: IAppBootstrapParams) {
        this._dbService = bootStrapParams.angular.db;
        this._tsUrlConfig = bootStrapParams.angular.tsUrlConfig;
        this._authService = bootStrapParams.angular.auth as IAuthService;
        this._safeApply = bootStrapParams.angular.safeApply;
        this._tsLocaleHelper = bootStrapParams.angular.tsLocaleHelper;
        this._window = bootStrapParams.globals.window;
        this._socketUrl = bootStrapParams.config.socketUrl;
        this._mobileClientURL = bootStrapParams.config.mobileClientURL;
        this._environmentService = bootStrapParams.asyncServices.environmentDetect;
    }

    initRootServices() {
        this._appState = this.initAppState();
        this._browserUtilsService = new BrowserUtilsService(this._window);
        this._eventsLogService = new EventLogsService(this._dbService.EventLog as IDbEventLog, EVENT_SOURCES.dashboard);
        this._redirectionService = new RedirectionService(
            this._window,
            this._dbService.System as IDbSystemInfo,
            this._browserUtilsService,
            this._eventsLogService,
            this._dbService.Rooms as IDbRooms
        );

        this._localizationService = new LocalizationService(this._dbService.CustomStrings as IDbCustomStrings);
        this._dbSmsService = new DbSmsAdapter(this._dbService);
        this._dbEmailService = new DbEmailAdapter(this._dbService);
        this._dbSsoService = new DbSso(this._dbService);
        this._dbScannerService = new DbScannerAdapter(this._dbService);
        this._dbSmartService = new DbSmartAdapter(this._dbService);
        this._dbHistoryService = new DbHistoryAdapter(this._dbService);
        this._dbVideoRecordingsService = new DbVideoRecordings(this._dbService);
        this._dbUserService = new DbUserAdapter(this._dbService);
        this._dbObservationService = new DbObservationAdapter(this._dbService);
        this._dbRoomsService = this._dbService.Rooms as IDbRooms;
        this._dbResourceService = new DbResourceAdapter(this._dbService);
        this._dbLocationService = this._dbService.Location as IDbLocation;
        this._dbReportsService = this._dbService.Reports as IDbReports;
        this._dbShortUrlService = this._dbService.ShortUrl as IDbShortUrl;
        this._dbOttService = this._dbService.OTT as IDbOtt;
        this._uiFlavorService = new UiFlavorService(
            this._browserUtilsService,
            this._dbService.Account as IDbAccount,
            this._environmentService
        );

        this._measureService = new MeasureService(this._eventsLogService);
        this._speedtestService = new SpeedtestService();
        this._webRtcDetector = new DetectWebRtcService(DETECT_RTC_ADVISED_TIMEOUT);

        this._timedOperation = new TsTimedOperationService();
        this._visibilityChangeService = new TsVisibilityChange(this._window);

        const defaultSocketConfig = TechseeSocketManager.getDefaultConfig(this._socketUrl);

        defaultSocketConfig.browserUtilsService = this._browserUtilsService;

        const socketConfig = this._browserUtilsService.getFromSessionStorage('socketConfig');

        if (socketConfig) {
            defaultSocketConfig.socketPath = socketConfig.path;
            defaultSocketConfig.sessionChannel.namespace = socketConfig.namespace;
        }

        TechseeSocketManager.setConfig(defaultSocketConfig);
        this._socketManager = TechseeSocketManager.instance();

        this._chatApi = new ChatApi(this._tsLocaleHelper);
        this._chatApi.setBrowserInfo({userAgent: this._window.navigator.userAgent});
        this._chatApi.setTechseeSocketManager(this._socketManager);
        this._chatApi.setSafeApply(this._safeApply);
        this._chatApi.init();
        this._chatApi.on('imageLoaded', (meta: object) => {
            this._eventsLogService?.info({
                roomId: this._chatApi.roomId,
                userId: this._chatApi.userId,
                logType: LOG_EVENTS.agentLoadedCustomerImage,
                meta
            });
        });

        this._chatApi.on('imageSendingFailed', (meta: object) => {
            this._eventsLogService?.error({
                roomId: this._chatApi.roomId,
                userId: this._chatApi.userId,
                logType: LOG_EVENTS.imageSendingFailed,
                meta
            });
        });

        this._accountSocketService = new AccountSocketService(
            this._socketManager.accountSocket,
            this._browserUtilsService,
            this._appState,
            this._dbUserService,
            this._dbService.Account,
            this._eventsLogService
        );

        this._applicationService = new ApplicationService(
            this._appState,
            this._authService,
            this._dbService.System as IDbSystemInfo,
            this._accountSocketService.joinAccountChannel.bind(this._accountSocketService),
            this._accountSocketService.disconnectAccountChannel.bind(this._accountSocketService)
        );

        this._authExpiryService = new AuthExpiryService(
            this._localizationService?.translate,
            this._applicationService,
            this._redirectionService,
            this._browserUtilsService,
            createFieldModel,
            this._eventsLogService,
            this._dbUserService
        );

        this._jsApiService = new JsApiService(
            this._authExpiryService,
            this._eventsLogService,
            this._browserUtilsService
        );

        this._wifiAnalyzerService = new WifiAnalyzerService(
            this._dbScannerService,
            this._dbRoomsService,
            this._eventsLogService,
            this._dbHistoryService
        );
    }

    get appState(): AppState {
        if (!this._appState) {
            throw new Error('AppState not initialized');
        }

        return this._appState;
    }

    get applicationService(): IApplicationService {
        if (!this._applicationService) {
            throw new Error('ApplicationService not initialized');
        }

        return this._applicationService;
    }
    get chatApi(): any {
        if (!this._chatApi) {
            throw new Error('ChatApi not initialized');
        }

        return this._chatApi;
    }

    get localizationService(): ILocalizationService {
        if (!this._localizationService) {
            throw new Error('localizationService not initialized');
        }

        return this._localizationService;
    }

    get browserUtilsService(): IBrowserUtilsService {
        if (!this._browserUtilsService) {
            throw new Error('browserUtilsService not initialized');
        }

        return this._browserUtilsService;
    }

    get environmentService(): ITsEnvironmentDetect {
        if (!this._environmentService) {
            throw new Error('tsEnvironmentDetect service not initialized');
        }

        return this._environmentService;
    }

    get displayTabletAsDesktop() {
        return this._browserUtilsService && this._browserUtilsService.getFromLocalStorage('displayTabletAsDesktop');
    }

    get domUtilsService(): IDomUtilsService {
        if (!this._domUtilsService) {
            this._domUtilsService = new DomUtilsService();
        }

        return this._domUtilsService;
    }

    get interactionSummaryService(): IInteractionSummaryService {
        if (!this._interactionSummaryService) {
            const baseRoot = this._tsUrlConfig.get('API_URL');

            this._interactionSummaryService = new InteractionSummaryService(baseRoot, this._authService.getAuthToken());
        }

        return this._interactionSummaryService;
    }

    get redirectionService(): IRedirectionService {
        if (!this._redirectionService) {
            throw new Error('redirectionService not initialized');
        }

        return this._redirectionService;
    }

    get dbSmartService(): IDbSmart {
        if (!this._dbSmartService) {
            throw new Error('dbSmartService not initialized');
        }

        return this._dbSmartService;
    }

    get dbSmsService(): IDbSms {
        if (!this._dbSmsService) {
            throw new Error('dbSmsService not initialized');
        }

        return this._dbSmsService;
    }

    get dbSsoService(): IDbSso {
        if (!this._dbSsoService) {
            throw new Error('dbSsoService not initialized');
        }

        return this._dbSsoService;
    }

    get dbScannerService(): IDbScanner {
        if (!this._dbScannerService) {
            throw new Error('dbScannerService not initialized');
        }

        return this._dbScannerService;
    }

    get dbEmailService(): IDbEmail {
        if (!this._dbEmailService) {
            throw new Error('dbEmailService not initialized');
        }

        return this._dbEmailService;
    }

    get eventsLogService() {
        if (!this._eventsLogService) {
            throw new Error('eventsLogService not initialized');
        }

        return this._eventsLogService;
    }

    get dbRoomsService(): IDbRooms {
        if (!this._dbRoomsService) {
            throw new Error('dbRoomsService not initialized');
        }

        return this._dbRoomsService;
    }

    get dbResourceService(): IDbResource {
        if (!this._dbResourceService) {
            throw new Error('dbResourceService not initialized');
        }

        return this._dbResourceService;
    }

    get dbLocationService(): IDbLocation {
        if (!this._dbLocationService) {
            throw new Error('dbLocationService not initialized');
        }

        return this._dbLocationService;
    }

    get dbReportsService(): IDbReports {
        if (!this._dbReportsService) {
            throw new Error('dbReportsService not initialized');
        }

        return this._dbReportsService;
    }

    get dbHistoryService(): IDbHistory {
        if (!this._dbHistoryService) {
            throw new Error('_dbHistoryService not initialized');
        }

        return this._dbHistoryService;
    }

    get dbVideoRecordingsService(): any {
        if (!this._dbVideoRecordingsService) {
            throw new Error('_dbVideoRecordingsService not initialized');
        }

        return this._dbVideoRecordingsService;
    }

    get dbUserService(): IDbUser {
        if (!this._dbUserService) {
            throw new Error('_dbUserService not initialized');
        }

        return this._dbUserService;
    }

    get dbAccountService(): IDbAccount {
        if (!this._dbService.Account) {
            throw new Error('dbAccountService not initialized');
        }

        return this._dbService.Account;
    }

    get dbObservationService(): IDbObservation {
        if (!this._dbObservationService) {
            throw new Error('_dbObservationService not initialized');
        }

        return this._dbObservationService;
    }

    get dbShortUrlService(): IDbShortUrl {
        if (!this._dbShortUrlService) {
            throw new Error('dbShortUrlService not initialized');
        }

        return this._dbShortUrlService;
    }

    get dbOttService(): IDbOtt {
        if (!this._dbOttService) {
            throw new Error('dbOttService not initialized');
        }

        return this._dbOttService;
    }

    get authService(): IAuthService {
        if (!this._authService) {
            throw new Error('authService not initialized');
        }

        return this._authService;
    }

    get uiFlavorService(): IUiFlavorService {
        if (!this._uiFlavorService) {
            throw new Error('uiFlavorService not initialized');
        }

        return this._uiFlavorService;
    }

    get authExpiryService(): IAuthExpiryService {
        if (!this._authExpiryService) {
            throw new Error('authExpiryService not initialized');
        }

        return this._authExpiryService;
    }

    get measureService(): IMeasureService {
        if (!this._measureService) {
            throw new Error('measureService not initialized');
        }

        return this._measureService;
    }

    get speedtestService(): ISpeedtestService {
        if (!this._speedtestService) {
            throw new Error('speedtestService not initialized');
        }

        return this._speedtestService;
    }

    get wifiAnalyzerService(): IWifiAnalyzerService {
        if (!this._wifiAnalyzerService) {
            throw new Error('wifiAnalyzerService not initialized');
        }

        return this._wifiAnalyzerService;
    }

    get webRtcDetector(): IWebRtcDetector {
        if (!this._webRtcDetector) {
            throw new Error('webRtcDetector not initialized');
        }

        return this._webRtcDetector;
    }

    get globals() {
        return {
            window: this._window
        };
    }

    get socketManager(): ITechseeSocketManager {
        if (!this._socketManager) {
            throw new Error('socketManager not initialized');
        }

        return this._socketManager;
    }

    get accountSocketService(): IAccountSocketService {
        if (!this._accountSocketService) {
            throw new Error('accountSocketService not initialized');
        }

        return this._accountSocketService;
    }

    get timedOperationService(): TsTimedOperationService {
        if (!this._timedOperation) {
            throw new Error('timedOperationService not initialized');
        }

        return this._timedOperation;
    }

    get visibilityChangeService(): IVisibilityChange {
        if (!this._visibilityChangeService) {
            throw new Error('visibilityChangeService not initialized');
        }

        return this._visibilityChangeService;
    }

    get jsApiService(): IJsApiService {
        if (!this._jsApiService) {
            throw new Error('jsApiService not initialized');
        }

        return this._jsApiService;
    }

    get mobileClientURL(): string {
        return this._mobileClientURL;
    }

    get safeApply() {
        return this._safeApply;
    }

    get interactionSummaryTranslated() {
        return {
            collapseAll: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.COLLAPSE_ALL'),
            expandAll: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.EXPAND_ALL'),
            noData: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.NO_DATA'),
            unknownError: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.UNKNOWN_ERROR'),
            refresh: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.REFRESH'),
            textAnalysis: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.TEXT.ANALYSIS'),
            [ScreenType.selectFlow]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.SELECT_FLOW'
            ),
            [ScreenType.capture]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.CAPTURE'
            ),
            [ScreenType.addImage]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.ADD_IMAGE'
            ),
            [ScreenType.recordAudio]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.RECORD_AUDIO'
            ),
            [ScreenType.textInput]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.TEXT_INPUT'
            ),
            [ScreenType.externalAPICall]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.API_CALL'
            ),
            [ScreenType.scan]: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.SCAN'),
            [ScreenType.textProcessor]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.TEXT_PROCESSOR'
            ),
            [ScreenType.imageAnalysis]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.IMAGE_ANALYSIS'
            ),
            [ScreenType.keyValue]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.KEY_VALUE'
            ),
            [ScreenType.form]: this._localizationService?.translate('REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.FORM'),
            [ScreenType.speedTest]: this._localizationService?.translate(
                'REACT.INTERACTION.SUMMARY.DISPLAY.BLOCK.SPEEDTEST'
            )
        };
    }

    getDateFormattedByAcc(date: string, format: string) {
        const dateFormat = format.split(' ')[0].toUpperCase();
        const timeFormat = format.split(' ')[1];
        const newFormat = dateFormat + (timeFormat ? ' ' + timeFormat : '');

        return moment(new Date(date)).utc().format(newFormat);
    }

    getDateFormattedByAccWifi(date: string, format: string) {
        const dateFormat = format.split(' ')[0].toUpperCase();
        const timeFormat = format.split(' ')[1];
        const newFormat = dateFormat + (timeFormat ? ' ' + timeFormat : '');

        return moment(new Date(date)).format(newFormat);
    }

    @action
    private initAppState(): AppState {
        if (!this._appState) {
            this._appState = new AppState();
            this._appState.dashboardLanguage = 'en_US';
        }

        return this._appState;
    }
}
