import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {VjHistoryController, IVjHistoryController, VjSession} from './controller';
import {getRootStore} from '../../app.bootstrap';
// @ts-ignore
import VjHistoryStateView from './vj-history.view.html';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {getDashboardLanguage} from '../helper/general.helper';

type SessionData = {
    interactionSummaryVjPreviews?: VjSession[];
    startDateValue?: Date | string;
    endDateValue?: Date | string;
    customerRef?: string;
};

class VjHistoryStateController {
    static $inject = ['tsStateHelper', 'accountData', 'tsUrlUtils'];

    private vjHistoryController: IVjHistoryController | undefined;

    private _browserUtilsService: IBrowserUtilsService;

    private readonly _domUtilsService: IDomUtilsService;

    private readonly urlParams: {endDate?: string; customerRef?: string; startDate?: string};

    private readonly isEmbedded: boolean = false;

    private readonly roomId: string = '';

    constructor(tsStateHelper: any, accountData: any, tsUrlUtils: any) {
        this._domUtilsService = getRootStore().domUtilsService;

        const localizationService = getRootStore().localizationService;
        const isInitialized = localizationService.isInitialized();

        const localServiceInitPromise = [Promise.resolve()];

        this.urlParams = {
            customerRef: tsUrlUtils.getParamValue('r'),
            startDate: tsUrlUtils.getParamValue('startDate'),
            endDate: tsUrlUtils.getParamValue('endDate')
        };

        this.isEmbedded =
            `${tsUrlUtils.getParamValue('emb')}` === 'true' ||
            tsUrlUtils.getParamValue('is')?.toLowerCase() === 'vjhistory';

        this.roomId = tsUrlUtils.getParamValue('roomId') ?? '';

        if (!isInitialized) {
            const accountLanguage = getDashboardLanguage(accountData);

            localizationService.setAccountData(accountData?._id, accountLanguage);
            localServiceInitPromise.push(localizationService.init());
        }

        Promise.all(localServiceInitPromise).finally(() => {
            getRootStore().safeApply(() => {
                this.initController(
                    accountData._id,
                    tsStateHelper,
                    localizationService,
                    accountData.protectedSettings.reportsDateFormat,
                    accountData.settings.timezone,
                    accountData.settings.language,
                    accountData.protectedSettings.interactionSummary.historySearchPerPage
                );
            });
        });

        this._browserUtilsService = getRootStore().browserUtilsService;
    }

    initController(
        accountId: string,
        tsStateHelper: any,
        localizationService: any,
        accDateFormat: string,
        timeZone: string,
        language: string,
        pageSize: number
    ) {
        const translate = localizationService.translate;
        const goToInvitePage = () => {
            tsStateHelper.safeGo('invite');
        };

        this.vjHistoryController = new VjHistoryController(
            translate,
            accountId,
            accDateFormat,
            timeZone,
            language,
            this._getSearchCriteria(),
            pageSize,
            this.isEmbedded ? undefined : goToInvitePage
        );

        if (this._canUseSessionStorage()) {
            this.vjHistoryController.updateVjList(this._getStoredResults());
        } else {
            this.vjHistoryController.searchHistory();
        }
    }
    private _cleanSessionStorage() {
        this._browserUtilsService.removeFromSessionStorage('interactionSummaryVJ');
    }

    /**
     * Gets search criteria from URL params or, alternatively, from session storage.
     * @private
     */
    private _getSearchCriteria(): typeof this.urlParams & {roomId?: string} {
        // If roomId is set it should be the only search criteria used
        if (this.roomId) {
            return {roomId: this.roomId};
        }

        if (!this._canUseSessionStorage()) {
            return this.urlParams;
        }

        const sessionData = this._getSessionData();

        if (sessionData) {
            return {
                customerRef: sessionData.customerRef,
                startDate:
                    sessionData.startDateValue instanceof Date
                        ? sessionData.startDateValue.toString()
                        : sessionData.startDateValue,
                endDate:
                    sessionData.endDateValue instanceof Date
                        ? sessionData.endDateValue.toString()
                        : sessionData.endDateValue
            };
        }

        return {};
    }

    private _getStoredResults(): VjSession[] {
        const sessionData = this._getSessionData();

        return sessionData?.interactionSummaryVjPreviews ?? [];
    }

    private _canUseSessionStorage(): boolean {
        const urlParamsEmpty =
            Object.values(this.urlParams).every((val) => val === '' || val === undefined) && !this.roomId;
        const sessionDataExists = this._getSessionData() !== undefined;

        return urlParamsEmpty && sessionDataExists;
    }

    private _getSessionData(): SessionData | undefined {
        return this._browserUtilsService.getFromSessionStorage('interactionSummaryVJ') as unknown as SessionData;
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

const view = {
    template: VjHistoryStateView,
    controller: 'VjHistoryStateController',
    controllerAs: 'vjhistory'
};

export default angular
    .module('states.vjHistory', [])
    .config(['$stateProvider', config])
    .controller('VjHistoryStateController', VjHistoryStateController);

function config($stateProvider: any) {
    $stateProvider.state('vjhistory', {
        url: '^' + ROUTE_BASE_PATH + 'vjHistory?authId&r&startDate&endDate&emb&is&roomId',
        views: {
            'desktop-view': view,
            'mobile-view': view
        },
        resolve: {
            // Expose the current user data
            currentUser: [
                'tsStateHelper',
                '$stateParams',
                async (tsStateHelper: any, $stateParams: any) => {
                    try {
                        const res = await getRootStore().dbUserService.find('current');

                        return res;
                    } catch (err: unknown) {
                        tsStateHelper.safeGo('login', $stateParams);

                        return Promise.reject(err);
                    }
                }
            ],
            // Expose the associated user account data, if he is associated
            accountData: [
                'currentUser',
                (currentUser: any) => {
                    if (!currentUser || !currentUser.accountId) {
                        return {};
                    }

                    return getRootStore().dbAccountService.find(currentUser.accountId);
                }
            ]
        }
    });
}
