import {IDbScanner} from './AngularServices';

export class DbScannerAdapter implements IDbScanner {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    scan(image: string, roomId?: string): Promise<any> {
        const payload = {image, ...(roomId ? {meta: {roomId}} : {})};

        return (this as any)._dbService.Scanner.scan('text', {data: payload});
    }
}
