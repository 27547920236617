import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {IWifiAnalyzerResults} from '../controller';
import {ConnectedDevicesBox} from './ConnectedDevicesBox';

interface ConnectedDevicesProps {
    networkDiscoveryDevices: IWifiAnalyzerResults['networkDiscoveryDevices'];
    ssid?: string;
}

export class ConnectedDevicesTab extends TechseeBaseComponent<ConnectedDevicesProps> {
    renderRoundedLinkIcon() {
        return (
            <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M17 7.5H14C13.45 7.5 13 7.95 13 8.5C13 9.05 13.45 9.5 14 9.5H17C18.65 9.5 20 10.85 20 12.5C20 14.15 18.65 15.5 17 15.5H14C13.45 15.5 13 15.95 13 16.5C13 17.05 13.45 17.5 14 17.5H17C19.76 17.5 22 15.26 22 12.5C22 9.74 19.76 7.5 17 7.5ZM8 12.5C8 13.05 8.45 13.5 9 13.5H15C15.55 13.5 16 13.05 16 12.5C16 11.95 15.55 11.5 15 11.5H9C8.45 11.5 8 11.95 8 12.5ZM10 15.5H7C5.35 15.5 4 14.15 4 12.5C4 10.85 5.35 9.5 7 9.5H10C10.55 9.5 11 9.05 11 8.5C11 7.95 10.55 7.5 10 7.5H7C4.24 7.5 2 9.74 2 12.5C2 15.26 4.24 17.5 7 17.5H10C10.55 17.5 11 17.05 11 16.5C11 15.95 10.55 15.5 10 15.5Z'
                    fill='#6B7280'
                />
            </svg>
        );
    }

    renderDeviceOutlineIcon() {
        return (
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M2.66667 4.00002H14.6667V2.66669H2.66667C1.93333 2.66669 1.33333 3.26669 1.33333 4.00002V11.3334H0V13.3334H9.33333V11.3334H2.66667V4.00002ZM15.3333 5.33335H11.3333C10.9667 5.33335 10.6667 5.63335 10.6667 6.00002V12.6667C10.6667 13.0334 10.9667 13.3334 11.3333 13.3334H15.3333C15.7 13.3334 16 13.0334 16 12.6667V6.00002C16 5.63335 15.7 5.33335 15.3333 5.33335ZM14.6667 11.3334H12V6.66669H14.6667V11.3334Z'
                    fill='currentColor'
                />
            </svg>
        );
    }

    renderInternal() {
        const {networkDiscoveryDevices, ssid} = this.props;

        return (
            <div className='wifi-analyzer-device'>
                <div className='wifi-analyzer-link-title'>
                    {this.renderRoundedLinkIcon()}
                    <p> {ssid ?? ''}</p>
                </div>

                <div className='wifi-analyzer-device-count'>
                    {this.renderDeviceOutlineIcon()}
                    <p>{networkDiscoveryDevices.length} Devices</p>
                </div>

                <div className='wifi-analyzer-device-linear' />

                {networkDiscoveryDevices &&
                    networkDiscoveryDevices.map((networkDiscoveryDevice, index) => (
                        <ConnectedDevicesBox key={index} networkDiscoveryDevice={networkDiscoveryDevice} />
                    ))}
            </div>
        );
    }
}
