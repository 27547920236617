import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {IWifiAnalyzerResults} from '../controller';
import get from 'lodash/get';

interface NetworkTabProps {
    results: IWifiAnalyzerResults;
}

export class NetworkTab extends TechseeBaseComponent<NetworkTabProps> {
    getDbmColor(dbm: number) {
        if (!dbm) {
            return '';
        }

        switch (true) {
            case dbm > -50:
                return 'dbm-green';
            case dbm < -70:
                return 'dbm-red';
            default:
                return 'dbm-orange';
        }
    }

    renderInternal() {
        const {results} = this.props;

        return (
            <div className='wifi-analyzer-content'>
                <div className={'item active'}>
                    <div className='wifi-analyzer-container'>
                        <div className='wifi-analyzer-container-left'>
                            <span className='wifi-analyzer-container-left-ssid'>
                                {results.wifiConnectionInfo ? (
                                    <span>
                                        CH {results.wifiConnectionInfo?.channel}
                                        <span className='gray-badge'>
                                            {results.wifiConnectionInfo?.frequency ?? '2.4 GHz'}
                                        </span>
                                    </span>
                                ) : (
                                    <span>&nbsp;</span>
                                )}

                                <span className='wifi-analyzer-container-left-name'>
                                    {results.wifiConnectionInfo?.ssid || results.name}
                                </span>
                            </span>
                            <span className='wifi-analyzer-container-left-mac'>
                                {results.wifiConnectionInfo?.macAddress}
                            </span>
                        </div>
                        <div className='wifi-analyzer-container-right'>
                            <div className='wifi-analyzer-container-badge'>
                                <Icon iconName={'link'} /> Connected
                            </div>

                            <div className='align-row'>
                                {results.wifiConnectionInfo ? (
                                    <span
                                        className={`${this.getDbmColor(results?.wifiConnectionInfo?.dBm)} column signal`}>
                                        <Icon style={{fontSize: '20px'}} iconName={'wifi-high'} />
                                        {results.wifiConnectionInfo.signalLevel}%
                                    </span>
                                ) : (
                                    <span
                                        className={`${this.getDbmColor(results?.wifiConnectionInfo?.dBm)} column signal`}></span>
                                )}
                                {results.wifiConnectionInfo ? (
                                    <span
                                        className={`${this.getDbmColor(results?.wifiConnectionInfo?.dBm)} column signal`}>
                                        <span>{results.wifiConnectionInfo.dBm}</span> dBm
                                    </span>
                                ) : (
                                    <span
                                        className={`${this.getDbmColor(results?.wifiConnectionInfo?.dBm)} column signal`}></span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='wifi-analyzer-container'>
                        <div className='wifi-analyzer-container-column'>
                            <div className='column-items'>
                                <Icon iconName={'lock'} /> Security
                                <span>{results.wifiConnectionInfo?.security || 'N/A'}</span>
                            </div>
                            <div className='column-items'>
                                <Icon iconName={'location'} /> Distance
                                <span>{results.wifiConnectionInfo?.distance || 'N/A'}</span>
                            </div>
                        </div>
                        <div className='wifi-analyzer-container-column'>
                            <div className='column-items'>
                                ↑ Upload
                                <span>{results.networkSpeed.upSpeed}</span>
                            </div>
                            <div className='column-items'>
                                ↓ Download
                                <span>{results.networkSpeed.downloadSpeed}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {results.scanAreaResults &&
                    results.scanAreaResults.map((device, idx) => (
                        <div key={idx} className={'item'}>
                            <div className='wifi-analyzer-container'>
                                <div className='wifi-analyzer-container-left'>
                                    <span className='wifi-analyzer-container-left-ssid'>
                                        <span>
                                            CH {device.channel}
                                            <span className='gray-badge'>{device.frequency ?? '2.4 GHz'}</span>
                                        </span>

                                        <span className='wifi-analyzer-container-left-name'>{device.ssid}</span>
                                    </span>
                                    <span className='wifi-analyzer-container-left-mac'>{device.macAddress}</span>
                                </div>
                                <div className='wifi-analyzer-container-right'>
                                    <span></span>
                                    <div className='align-row'>
                                        <span className={`${this.getDbmColor(device?.dBm)} column signal`}>
                                            <Icon style={{fontSize: '20px'}} iconName={'wifi-high'} />
                                            {device.signalLevel}%
                                        </span>

                                        <span className={`${this.getDbmColor(device?.dBm)} column signal`}>
                                            <span>{device.dBm}</span> dBm
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='wifi-analyzer-container'>
                                <div className='wifi-analyzer-container-column align-row'>
                                    <div className='column-items'>
                                        <Icon iconName={'lock'} /> Security
                                        <span>{device.security || 'N/A'}</span>
                                    </div>
                                    <div className='column-items'>
                                        <Icon iconName={'location'} /> Distance
                                        <span>{device.distance || 'N/A'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}
